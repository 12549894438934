// styles
import { Typography } from "@mui/material"
import { customStyled } from "styles/theme"

export const SProfileInfoWrapper = customStyled("div")(({ theme: { palette, spacing, shape, breakpoints } }) => ({
  display: "flex",
  width: "100%",
  padding: `${spacing(11)} ${spacing(9)}`,
  backgroundColor: palette.black.lacquer[950],
  borderRadius: shape.borderRadius(8),
  marginTop: spacing(13),
  marginBottom: spacing(8),
  [breakpoints.down("xs")]: {
    padding: spacing(9),
    marginTop: spacing(8),
  },
}))

export const STitle = customStyled(Typography)(
  ({
    theme: {
      breakpoints,
      spacing,
      typography: { mobileH3 },
    },
  }) => ({
    marginBottom: spacing(8),
    [breakpoints.down("xs")]: {
      ...mobileH3,
    },
  })
)
