import { NavLink } from "react-router-dom"
// hooks
import useCardSizes from "./hooks/useCardSizes"
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
// styles
import { customStyled } from "styles/theme"
import { shouldForwardProp } from "styles/helpers"
// types
import { CardWrapperProps, CardWrapperStyleProps } from "./types"

export const CardWrapper = customStyled("div", {
  shouldForwardProp: (prop) =>
    shouldForwardProp<CardWrapperStyleProps>(
      ["width", "height", "backgroundImage", "backgroundHoverMedia", "sx"],
      prop
    ),
})<CardWrapperProps>(
  ({
    theme: {
      spacing,
      palette,
      shape: { borderRadius },
      transitions: {
        duration: { standard },
        easing: { easeInOut },
      },
      cardSizes,
      zIndex,
    },
    backgroundImage,
    backgroundHoverMedia,
    variant,
    width = 405,
    height = 260,
  }) => {
    const { cardWidth, cardHeight } = useCardSizes({ cardSizes, variant, width, height })
    const { isSmallDesktop } = useMUIBreakpoints()

    return {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      justifyContent: "space-between",
      boxSizing: "border-box",
      padding: isSmallDesktop ? spacing(4) : spacing(5),
      borderRadius: borderRadius(8),
      color: palette.white.main,
      width: cardWidth,
      height: cardHeight,
      overflow: "hidden",
      willChange: "transform",
      "&::before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: zIndex.cardBackground,
        width: cardWidth,
        height: cardHeight,
        borderRadius: borderRadius(8),
        background: `url(${backgroundImage}) no-repeat left top`,
        backgroundSize: "cover",
        transition: `transform ${standard}ms ${easeInOut}`,
      },
      "&:hover::before": {
        borderRadius: borderRadius(8),
        ...(backgroundHoverMedia
          ? { background: `url(${backgroundHoverMedia}) no-repeat center top` }
          : { transform: "scale(1.05)" }),
      },

      ".course-card__progress-bar": {
        transition: `all ${standard}ms ${easeInOut}`,
        opacity: 1,
      },
      "&:hover .course-card__progress-bar": {
        opacity: 0,
        marginTop: 0,
        transform: "translateY(200%)",
      },
    }
  }
)

export const NavLinkWrapper = customStyled(NavLink)(() => ({
  textDecoration: "none",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}))
