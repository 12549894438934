export const Z_INDEXES = Object.freeze({
  negative: -20,
  cardBackground: -1,
  base: 0,
  cardOverflow: 2,
  menu: 50,
  modal: 100,
  deepModal: 150,
  error: 250,
  overAll: 800,
})

export const CARD_SIZES_MAPPING = Object.freeze({
  course: { width: 405, height: 260, mobileWidth: 271, mobileHeight: 200 },
  singleVideoCourse: { width: 262, height: 440, mobileWidth: 158, mobileHeight: 260 },
  singleShortVideo: { width: 190, height: 260, mobileWidth: 158, mobileHeight: 200 },
  brandPrimary: { width: 190, height: 260, mobileWidth: 158, mobileHeight: 200 },
  brandSecondary: { width: 190, height: 260, mobileWidth: 158, mobileHeight: 200 },
  viewAllCourse: { width: 190, height: 260, mobileWidth: 158, mobileHeight: 200 },
  viewAllShortVideo: { width: 262, height: 440, mobileWidth: 158, mobileHeight: 260 },
  specialty: { width: 167, height: 124, mobileWidth: 158, mobileHeight: 104 },
})
