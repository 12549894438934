// constants
import { ICON_MAPPINGS } from "constants/icons"
import { memo, MouseEvent } from "react"
// styles
import { Background } from "./styles"
// types
import { IconProps } from "./types"

const Icon = ({
  name,
  fillColor,
  onClick,
  withBackground,
  iconSize = "medium",
  backgroundSize = "medium",
  backgroundVariant = "primary",
  sx,
  disabled,
}: IconProps) => {
  // @ts-expect-error
  const IconComponent = ICON_MAPPINGS[iconSize][name]

  if (!IconComponent) return null

  return (
    <Background
      onClick={
        !disabled
          ? onClick
          : (e: MouseEvent<HTMLDivElement>) => {
              e.stopPropagation()
            }
      }
      withBackground={withBackground}
      fillColor={fillColor}
      backgroundSize={backgroundSize}
      backgroundVariant={backgroundVariant}
      sx={sx}
      disabled={disabled}
    >
      {<IconComponent display="block" />}
    </Background>
  )
}

export default memo(Icon)
