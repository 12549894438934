// styles
import { customStyled } from "styles/theme"

export const SPassedCmeTestsWrapper = customStyled("div")(({ theme: { spacing, breakpoints } }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [breakpoints.down("sm")]: {
    padding: spacing(5),
  },
}))

export const SDescriptionWrapper = customStyled("div")(
  ({
    theme: {
      palette,
      shape: { borderRadius },
      spacing,
      breakpoints,
    },
  }) => ({
    width: "437px",
    backgroundColor: palette.green.mid,
    borderRadius: borderRadius(6),
    padding: spacing(9),

    [breakpoints.down("sm")]: {
      width: "100%",
    },
  })
)

export const SPassedCmeTestWrapper = customStyled("div")(
  ({
    theme: {
      palette,
      shape: { borderRadius },
      spacing,
      breakpoints,
    },
  }) => ({
    width: "437px",
    backgroundColor: palette.black.lacquer[950],
    borderRadius: borderRadius(6),
    marginBottom: spacing(5),
    padding: spacing(5),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [breakpoints.down("sm")]: {
      width: `calc(100vw - ${spacing(5)} - ${spacing(5)})`,
    },
  })
)

export const SPassedCmeTestContentWrapper = customStyled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}))
