import { useQuery } from "@tanstack/react-query"
import { useState, useCallback } from "react"
// api
import { COURSE_WATCH_KEYS, getSuperCourseProgressesByStatus, GetSuperCourseProgressesByStatusResponse } from "api"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// utils
import { getNormalizedCourses } from "./utils"
// constants
import { COURSE_PROGRESS_STATUSES } from "constants/courseProgress"
// types
import { ValueOf } from "types/generics"
import { NormalizedCourses } from "./utils"

export const DETAIL_SCREENS = Object.freeze({
  WATCHED_COURSES: "watchedCourses",
  PASSED_CME_TESTS: "passesCmeTests",
})

type DetailScreens = ValueOf<typeof DETAIL_SCREENS> | null

export const useCmeDashboard = (): {
  userCmePoints?: number
  watchedCoursesCount: number
  passedCmeTestCoursesCount: number
  watchedCoursesByYears: NormalizedCourses
  passedCmeTestCoursesByYears: NormalizedCourses
  handleOpenWatchedCourses: () => void
  handleOpenPassedCmeTests: () => void
  detailScreenOpen: DetailScreens
  handleCloseDetailScreen: () => void
  isLoading: boolean
} => {
  const {
    user: { cmePoints: userCmePoints },
  } = useAuthenticationContext()

  const [detailScreenOpen, setDetailScreenOpen] = useState<DetailScreens>(null)

  const { data: courseProgresses, isLoading: isCoursesProgressesLoading } =
    useQuery<GetSuperCourseProgressesByStatusResponse>({
      queryKey: [COURSE_WATCH_KEYS.GET_SUPER_COURSES_PROGRESS_WATCHED, COURSE_PROGRESS_STATUSES.WATCHED],
      queryFn: () => getSuperCourseProgressesByStatus({ status: COURSE_PROGRESS_STATUSES.WATCHED }),
    })

  const watchedCoursesByYears = getNormalizedCourses(courseProgresses)

  const passedCmeTestCoursesByYears: NormalizedCourses = {}

  for (const year in watchedCoursesByYears) {
    passedCmeTestCoursesByYears[year] = watchedCoursesByYears[year].filter(({ cmeTestPassed }) => cmeTestPassed)
  }

  const watchedCoursesCount = Object.values(watchedCoursesByYears).flat().length
  const passedCmeTestCoursesCount = Object.values(passedCmeTestCoursesByYears).flat().length

  const handleOpenWatchedCourses = useCallback(() => {
    setDetailScreenOpen(DETAIL_SCREENS.WATCHED_COURSES)
  }, [detailScreenOpen])

  const handleOpenPassedCmeTests = useCallback(() => {
    setDetailScreenOpen(DETAIL_SCREENS.PASSED_CME_TESTS)
  }, [detailScreenOpen])

  const handleCloseDetailScreen = useCallback(() => {
    setDetailScreenOpen(null)
  }, [detailScreenOpen])

  return {
    userCmePoints,
    watchedCoursesCount,
    passedCmeTestCoursesCount,
    watchedCoursesByYears,
    passedCmeTestCoursesByYears,
    handleOpenWatchedCourses,
    handleOpenPassedCmeTests,
    detailScreenOpen,
    handleCloseDetailScreen,
    isLoading: isCoursesProgressesLoading,
  }
}
