import { useQuery } from "@tanstack/react-query"
// api
import { USER_NOTIFICATIONS, getUserUnreadNotification, GetUserUnreadNotificationResponse } from "api"

export const useUnreadNotification = () => {
  const { data: fetchedUserUnreadNotifications } = useQuery<GetUserUnreadNotificationResponse>({
    queryKey: [USER_NOTIFICATIONS.GET_USER_UNREAD_NOTIFICATION],
    queryFn: () => getUserUnreadNotification(),
  })

  return {
    haveUnreadNotification: fetchedUserUnreadNotifications?.data[0]?.attributes?.isRead === false,
  }
}
