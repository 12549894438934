import { useTranslation } from "react-i18next"
import { Typography } from "@mui/material"
// hooks
import { useNotifications } from "./hooks/useNotifications"
// components
import { SwitchButton } from "shared/components/input"
// styles
import { SInnerWrapper } from "../styles"

const Notifications = () => {
  const { t } = useTranslation()

  const {
    subscribeNewCourses,
    subscribeYourQuestionAnswered,
    handleToggleNewCourseSubscribe,
    handleToggleQuestionAnsweredSubscribe,
    isLoading,
  } = useNotifications()

  return (
    <SInnerWrapper
      sx={[
        ({ spacing, breakpoints }) => ({
          margin: `${spacing(13)} auto`,
          alignItems: "flex-start",
          [breakpoints.down("xs")]: {
            margin: `${spacing(11)} auto`,
          },
        }),
      ]}
    >
      <Typography mb={8} variant="h4">
        {t("settings:notifications:title")}
      </Typography>
      <SwitchButton
        label={t("settings:notifications:newCourseLabel")}
        checked={subscribeNewCourses}
        onChange={handleToggleNewCourseSubscribe}
        disabled={isLoading}
      />
      <SwitchButton
        label={t("settings:notifications:questionAnsweredLabel")}
        checked={subscribeYourQuestionAnswered}
        onChange={handleToggleQuestionAnsweredSubscribe}
        disabled={isLoading}
      />
    </SInnerWrapper>
  )
}

export default Notifications
