import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
import { useUnreadNotification } from "../hooks/useUnreadNotification"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// components
import NavLink from "shared/components/NavLink"
// constants
import { PURCHASED } from "constants/routes"
import { navigationLinks } from "./constants"

const NavigationMenu = () => {
  const { t } = useTranslation()
  const { isTablet } = useMUIBreakpoints()
  const {
    user: { purchasedSuperCoursesIds },
  } = useAuthenticationContext()

  const { haveUnreadNotification } = useUnreadNotification()

  return (
    <Box
      component="nav"
      sx={[
        ({ breakpoints, spacing, palette: { black }, zIndex: { menu } }) => ({
          marginBottom: "auto",

          [breakpoints.down("sm")]: {
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: `${spacing(6)} ${spacing(8)}`,
            background: black.lacquer["800"],
            zIndex: menu,
          },
        }),
      ]}
    >
      {navigationLinks.map(({ path, iconName, tKey }) => {
        if (iconName === "notification" && haveUnreadNotification) iconName = "notificationActive"

        if (path === PURCHASED && !purchasedSuperCoursesIds?.length) return

        return (
          <NavLink key={tKey} icon={iconName} to={path} sxLink={{ lineHeight: 1.2 }}>
            {!isTablet && t(tKey)}
          </NavLink>
        )
      })}
    </Box>
  )
}

export default NavigationMenu
