// routes
import AppRoutes from "./AppRoutes"
// components
import Matomo from "./Matomo"
// styles
import "assets/fonts/fonts.css"

const App = () => {
  return (
    <Matomo>
      <AppRoutes />
    </Matomo>
  )
}

export default App
