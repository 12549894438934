import { useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
// api
import { COMMON_INFORMATION, getTermsOfUse, GetTermsOfUseResponse } from "api"
// types
import { Language } from "types/i18n"

export const useTerms = (): {
  termsOfUse: string
  isLoading: boolean
} => {
  const { i18n } = useTranslation()

  const { data: fetchedTermsOfUse, isLoading } = useQuery<GetTermsOfUseResponse>({
    queryKey: [COMMON_INFORMATION.GET_TERMS_OF_USE, i18n.language],
    queryFn: () => getTermsOfUse({ locale: i18n.language as Language }),
  })

  const termsOfUse = useMemo(() => fetchedTermsOfUse?.data?.attributes?.termsOfUse || "", [fetchedTermsOfUse])

  return {
    termsOfUse,
    isLoading,
  }
}
