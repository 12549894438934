import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
// services
import { changePassword } from "api/user"

const useChangePassword = () => {
  const [error, setError] = useState()

  const {
    mutate: _changePassword,
    isLoading,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: changePassword,
    onError: (err: any) => {
      setError(err?.response?.data?.error?.message)
    },
  })

  return { resetPassword: _changePassword, isLoading, isError, isSuccess, error }
}

export default useChangePassword
