import axios from "axios"
import qs from "qs"
// utils
import { buildQueryString } from "utils/buildQueryString"
// types
import { FetchedUser, UserProfile } from "types/user"

export type GetStrapiUserResponse = FetchedUser

export type UpdateUserDataRequest = { userProfileId: number } & Partial<UserProfile> &
  Partial<{ userPicture: string | File }>

export type UpdateUserDataResponse = FetchedUser | Record<string, unknown>

export const USER_KEYS = {
  STRAPI_USER: "strapiUser",
  UPDATE_USER_LANGUAGE: "updateUserLanguage",
  USER_CONFIRMATION: "userConfirmation",
}

export const getStrapiUser = async () => {
  const params = qs.stringify(
    {
      fields: [
        "id",
        "username",
        "email",
        "provider",
        "confirmed",
        "blocked",
        "createdAt",
        "updatedAt",
        "acceptedTermsAndConditions",
        "uuid",
        "acceptedEmailNewsletter",
      ],
      populate: {
        user_profile: {
          fields: [
            "id",
            "title",
            "userFirstName",
            "userLastName",
            "userJobTitle",
            "userLocale",

            "cmePoints",
            "certificatesEarned",
            "coursesCompleted",
            "createdAt",
            "publishedAt",
            "updatedAt",
            "studentDocumentURL",
            "studentNumber",
            "activeMembership",
            "userCountry",
            "userDateOfBirth",
            "userEFN",
            "userGender",
            // "userJobOccupation", field does not exist in Strapi,
            "userJobPosition",
            "userPartnercode",
            "subscribeNewCourses",
            "subscribeYourQuestionAnswered",
          ],
          populate: {
            userPicture: {
              fields: ["url", "id"],
            },
            specialty: {
              fields: ["name"],
            },
            purchasedSuperCourses: {
              fields: ["id"],
            },
            subscribedPartnerChannels: {
              fields: ["partnerName"],
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/users/me?${params}`

  const response = await axios.get<GetStrapiUserResponse>(url)

  return response.data
}

type GetIsExistStrapiUserByEmailRequest = string
export type GetIsExistStrapiUserByEmailResponse = boolean

export const getIsExistStrapiUserByEmail = async (email: GetIsExistStrapiUserByEmailRequest) => {
  const params = buildQueryString({ email: email.toLocaleLowerCase() })

  const url = `/user-exist?${params}`

  const response = await axios.get<GetIsExistStrapiUserByEmailResponse>(url)

  return response.data
}

type GetStrapiUserByEmailRequest = string
export type GetStrapiUserByEmailResponse = FetchedUser[]

type KalturaSessionLoginRequest = string
export type KalturaSessionLoginResponse = string

export const getStrapiUserByEmail = async (email: GetStrapiUserByEmailRequest) => {
  const params = qs.stringify(
    {
      filters: {
        email: {
          $eqi: email,
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/users/?${params}`

  const response = await axios.get<GetStrapiUserByEmailResponse>(url)

  return response.data
}

export const updateUserData = async ({ userProfileId, ...userData }: UpdateUserDataRequest) => {
  const url = `/user-profiles/${userProfileId}`

  const response = await axios.put(url, {
    data: {
      ...userData,
    },
  })

  return response.data
}

type ChangePasswordRequest = {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

export const changePassword = async ({ currentPassword, password, passwordConfirmation }: ChangePasswordRequest) => {
  const params = qs.stringify(
    {
      populate: "*",
    },
    { encodeValuesOnly: true }
  )

  const url = `/auth/change-password?${params}`

  const response = await axios.post<unknown>(url, { currentPassword, password, passwordConfirmation })

  return response.data
}

type UserConfirmationRequest = {
  confirmationToken: string
  email: string
}

export type UserConfirmationResponse = {
  userConfirmed: boolean
  emailSent?: boolean
  jwt?: string
}

export const userConfirmation = async ({ confirmationToken, email }: UserConfirmationRequest) => {
  const params = qs.stringify(
    {
      confirmation: confirmationToken,
      email,
    },
    { encodeValuesOnly: true }
  )

  const url = `/user-confirmation?${params}`

  const response = await axios.get<UserConfirmationResponse>(url)

  return response.data
}

export const kalturaSessionLogin = async (userId: KalturaSessionLoginRequest) => {
  const url = `/kaltura-session?userId=${userId}`

  const response = await axios.get<KalturaSessionLoginResponse>(url)

  return response.data
}
