import { useMediaQuery } from "@mui/material"
import { CustomTheme } from "styles/theme"

export const useMUIBreakpoints = () => {
  const isMobile = useMediaQuery((theme: CustomTheme) => theme.breakpoints.down("xs"))
  const isTablet = useMediaQuery((theme: CustomTheme) => theme.breakpoints.down("sm"))
  const isSmallDesktop = useMediaQuery((theme: CustomTheme) => theme.breakpoints.down("md"))
  const isDesktop = useMediaQuery((theme: CustomTheme) => theme.breakpoints.down("lg"))
  const isLargeDesktop = useMediaQuery((theme: CustomTheme) => theme.breakpoints.down("xl"))

  return {
    isMobile,
    isTablet,
    isSmallDesktop,
    isDesktop,
    isLargeDesktop,
  }
}
