import { memo } from "react"
import { Box, Typography, CircularProgress } from "@mui/material"
// types
import { CircleProgressProps } from "./types"

const CircleProgress = ({
  maxValue,
  currentValue,
  additionalText,
  hideText,
  withBackground,
  disableBlur = false,
  size = "medium",
  textVariant = "description",
  sxText,
  ...muiCurcularProps
}: CircleProgressProps) => {
  const value = currentValue / (maxValue / 100)
  let circularSize

  switch (size) {
    case "medium":
      circularSize = 48
      break
    case "large":
      circularSize = 140
      break
    case "xlarge":
      circularSize = 200
      break
    case "mobileMedium":
      circularSize = 36
      break
    default:
      circularSize = 48
  }

  return (
    <Box width={circularSize} height={circularSize} position="relative" display="inline-block">
      <CircularProgress
        value={value}
        variant="determinate"
        color="secondary"
        size={circularSize}
        sx={[
          () => ({
            borderRadius: "50%",
            backdropFilter: "blur(6px)",
            ...(withBackground && {
              backgroundColor: "rgba(105,105,105, .4)",
              borderRadius: "50%",
            }),
            ...(disableBlur && {
              backdropFilter: "none",
            }),
          }),
        ]}
        {...muiCurcularProps}
      />
      <CircularProgress
        value={100}
        variant="determinate"
        size={circularSize}
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          color: "rgba(255, 255, 255, 0.16)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          translate: "-50% -50%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {!hideText && (
          <Typography variant={textVariant} component="p" sx={sxText}>
            {currentValue}/{maxValue}
          </Typography>
        )}
        {additionalText && (
          <Typography mt={2} color="black.lacquer.300" variant="description">
            {additionalText}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default memo(CircleProgress)
