import { customStyled } from "styles/theme"
//components
import Button from "shared/components/Button"

export const RedirectButton = customStyled(Button)(
  ({
    theme: {
      palette: { green },
      typography: { h5, mobileH3 },
      breakpoints,
    },
  }) => ({
    "&.MuiButton-root": {
      ...h5,
      color: green.hyperpop[200],
      backgroundColor: green.mid,
      width: "100%",
      [breakpoints.down("sm")]: {
        ...mobileH3,
      },
    },
  })
)

export const InnerWrapper = customStyled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  height: "100%",
}))
