import { Box, Avatar, Typography } from "@mui/material"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import remarkBreaks from "remark-breaks"
import { useState, useMemo, useCallback } from "react"
import { useTranslation } from "react-i18next"
// components
import MainLayout from "pages/layouts/MainLayout"
import Container from "shared/components/Container"
import GlobalLoading from "shared/components/GlobalLoading"
import Button from "shared/components/Button"
import { CourseCard } from "shared/components/cards"
// constants
import { REACT_APP_STRAPI_URL } from "constants/endpoints"
import { courseSharePath } from "constants/routes"
// hooks
import { useChannel } from "./hooks/useChannel"
// styles
import { SNavigation, SChannelInfo } from "./styles"

const Channel = () => {
  const { t } = useTranslation()

  const { isLoading, channel, courseCards, isAuthenticated, handleSubscribe, isSubscribed, isLoadingSubscribe } =
    useChannel()
  const [tabValue, setTabValue] = useState(1)

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue)
    },
    [setTabValue]
  )

  const isInfoTab = useMemo(() => tabValue === 2, [tabValue])

  if (isLoading || !channel) return <GlobalLoading />

  return (
    <MainLayout disableDesktopNavigation={!isAuthenticated} disableMobileNavigation={!isAuthenticated}>
      <Container limited>
        <Box
          component="img"
          sx={[
            ({ breakpoints }) => ({
              width: "100%",
              height: "auto",
              borderRadius: "24px",
              [breakpoints.down("sm")]: {
                borderRadius: 0,
                width: "100vw",
                ml: "-16px",
              },
            }),
          ]}
          mt={8}
          mb={5}
          alt="Channel banner"
          src={`${REACT_APP_STRAPI_URL}${channel.channelBanner?.url}`}
        />
        <SNavigation>
          <Box
            sx={[
              ({ breakpoints }) => ({
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                [breakpoints.down("sm")]: {
                  flexDirection: "column",
                  textAlign: "center",
                },
              }),
            ]}
          >
            <Avatar
              sx={[
                ({ spacing, breakpoints }) => ({
                  width: "86px",
                  height: "86px",
                  marginRight: spacing(5),
                  [breakpoints.down("sm")]: {
                    marginRight: 0,
                    marginBottom: spacing(3),
                    width: "50px",
                    height: "50px",
                  },
                }),
              ]}
              alt="Channel logo"
              src={`${REACT_APP_STRAPI_URL}${channel.partnerLogo?.url}`}
            />
            <Typography display="block" variant="h4" sx={{ maxWidth: "260px" }}>
              {channel.partnerName}
            </Typography>
          </Box>
          <Button
            onClick={handleSubscribe}
            color="secondary"
            variant="contained"
            sx={[
              ({ breakpoints, spacing, palette }) => ({
                opacity: isLoadingSubscribe ? "0.8" : 1,
                transition: "opacity .2s ease-out",
                color: `${channel.subscribeButtonColor || palette.green.dark} !important`,
                backgroundColor: `${channel.subscribeButtonBackgroundColor || palette.green.hyperpop[400]} !important`,
                [breakpoints.down("sm")]: {
                  marginTop: spacing(6),
                },
                [breakpoints.down("xs")]: {
                  width: "100%",
                },
              }),
            ]}
            disabled={isLoadingSubscribe}
          >
            {isSubscribed ? t("channel:unsubscribeButtonText") : t("channel:subscribeButtonText")}
          </Button>
        </SNavigation>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="secondary"
          sx={[
            ({ breakpoints, spacing, palette, typography }) => ({
              "& .MuiButtonBase-root": {
                ...typography.h4,
                color: palette.white.main,
                textTransform: "none",

                "&.Mui-selected": { color: palette.green.hyperpop[500] },
              },
              "& .MuiTabs-flexContainer": { borderBottom: `${palette.black.lacquer[500]} 2px solid` },
              marginTop: spacing(8),
              [breakpoints.down("sm")]: {
                marginTop: spacing(5),
              },
            }),
          ]}
        >
          <Tab label={t("channel:tabs:videos")} value={1} />
          <Tab label={t("channel:tabs:info")} value={2} />
        </Tabs>
        {isInfoTab ? (
          <SChannelInfo>
            <ReactMarkdown remarkPlugins={[remarkGfm, remarkBreaks]}>{channel?.channelDescription}</ReactMarkdown>
          </SChannelInfo>
        ) : (
          <Box
            sx={[
              ({ breakpoints }) => ({
                mt: 8,
                display: "grid",
                gridGap: "16px",
                gridTemplateColumns: "repeat(12, 1fr)",
                gridAutoRows: "260px",
                gridAutoFlow: "dense",
                [breakpoints.down("md")]: {
                  gridAutoRows: "200px",
                },
              }),
            ]}
          >
            {courseCards.map(
              (
                {
                  title,
                  backgroundImage,
                  backgroundImageHash,
                  backgroundHoverMedia,
                  circleMaxValue,
                  circleCurrentValue,
                  episodesCount,
                  episodesWatched,
                  topTags,
                  bottomTags,
                  variant,
                  superCourseTitle,
                },
                idx
              ) => (
                <Box
                  key={title + idx}
                  sx={[
                    ({ breakpoints }) => ({
                      borderRadius: "16px",
                      overflow: "hidden",
                      gridColumn: variant === "course" ? "span 4" : "span 2",
                      [breakpoints.down("lg")]: {
                        gridColumn: variant === "course" ? "span 6" : "span 3",
                      },
                      [breakpoints.down("xs")]: {
                        gridColumn: "span 6",
                      },
                      [breakpoints.down("xxs")]: {
                        gridColumn: variant === "course" ? "span 12" : "span 6",
                      },
                    }),
                  ]}
                >
                  <CourseCard
                    width="100%"
                    height="100%"
                    key={title + idx}
                    title={title}
                    backgroundImage={backgroundImage}
                    backgroundImageHash={backgroundImageHash}
                    backgroundHoverMedia={backgroundHoverMedia}
                    circleMaxValue={circleMaxValue}
                    circleCurrentValue={circleCurrentValue}
                    episodesCount={episodesCount}
                    episodesWatched={episodesWatched}
                    topTags={topTags}
                    bottomTags={bottomTags}
                    redirectUrl={courseSharePath(superCourseTitle)}
                  />
                </Box>
              )
            )}
          </Box>
        )}
      </Container>
    </MainLayout>
  )
}

export default Channel
