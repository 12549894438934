import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useMemo, useEffect, useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// api
import {
  CHANNEL_KEYS,
  getChannel,
  GetChannelResponse,
  Channel,
  subscribeChannel,
  SubscribeChannelResponse,
  unsubscribeChannel,
  UnsubscribeChannelResponse,
} from "api"
// constants
import { COURSE_TYPE_TRANSFORMER, newTag } from "constants/courses"
import { REACT_APP_STRAPI_URL } from "constants/endpoints"
import { HOME, ENTRY_POINT } from "constants/routes"
// types
import { Language } from "types/i18n"
import { Card } from "utils/cards"
import { TagsSetTag } from "shared/components/cards/components/TagsSet/types"
// utils
import { addMonths, isBefore } from "utils/date"

export type ChannelCourseCards = Card & { superCourseTitle: "string" }

export const useChannel = (): {
  isLoading: boolean
  courseCards: ChannelCourseCards[]
  isAuthenticated: boolean
  handleSubscribe: () => void
  channel?: Channel
  isSubscribed?: boolean
  isLoadingSubscribe: boolean
} => {
  const { partnerName } = useParams<{ partnerName: string }>()

  const { t, i18n } = useTranslation()
  const {
    user: { id: userId, subscribedPartnerChannels },
  } = useAuthenticationContext()
  const navigate = useNavigate()

  const isAuthenticated = useMemo(() => !!userId, [userId])
  const [isSubscribed, setSubscribed] = useState<boolean>(false)

  const { isLoading, data: fetchedChannel } = useQuery<GetChannelResponse>(
    [CHANNEL_KEYS.GET_CHANNEL, i18n.language, partnerName],
    () => getChannel({ partnerName: partnerName!, locale: i18n.language as Language })
  )

  const { refetch: fetchSubscribeChannel, isFetching: isLoadingSubscribeChannel } = useQuery<SubscribeChannelResponse>({
    queryKey: [CHANNEL_KEYS.SUBSCRIBE_CHANNEL, partnerName],
    queryFn: () => subscribeChannel({ partnerName: partnerName! }),
    onSuccess: ({ status }) => status === "done" && setSubscribed(true),
    enabled: false,
  })

  const { refetch: fetchUnsubscribeChannel, isFetching: isLoadingUnsubscribeChannel } =
    useQuery<UnsubscribeChannelResponse>({
      queryKey: [CHANNEL_KEYS.UNSUBSCRIBE_CHANNEL, partnerName],
      queryFn: () => unsubscribeChannel({ partnerName: partnerName! }),
      onSuccess: ({ status }) => status === "done" && setSubscribed(false),
      enabled: false,
    })

  useEffect(() => {
    // @ts-ignore
    if (fetchedChannel?.name === "NotFoundError") {
      navigate(HOME)

      return
    }
  }, [fetchedChannel])

  useEffect(() => {
    if (!(subscribedPartnerChannels && fetchedChannel)) return

    setSubscribed(!!subscribedPartnerChannels.find(({ partnerName }) => partnerName === fetchedChannel?.partnerName))
  }, [fetchedChannel, subscribedPartnerChannels])

  const courseCards: any = useMemo(
    () =>
      fetchedChannel?.courses.map(
        ({
          id,
          courseName,
          courseType,
          publishedAt,
          cmePointsText,
          certified,
          courseLengthText,
          hashCourseCoverLandscape,
          hashCourseCover,
          cover,
          coverLandscape,
          speaker,
          superCourse,
        }) => {
          const isSingleVideoCourse = courseType === "shortUnit" || courseType === "journal"
          const { speakerTitle = "", speakerFirstName = "", speakerLastName = "" } = speaker || {}

          const isNewCourse = isBefore(Date.now(), addMonths(1, new Date(publishedAt)).getTime())
          const topTags: TagsSetTag[] = [{ title: courseLengthText, variant: "primary" }]
          const bottomTags: TagsSetTag[] = [
            {
              title: t(`shared:courseTypes:${courseType}`),
              variant: "primary",
            },
          ]

          if (isSingleVideoCourse) {
            if (isNewCourse) {
              topTags.unshift(newTag(t))
            }

            return {
              courseId: id,
              title: courseName,
              backgroundImage: REACT_APP_STRAPI_URL + cover?.url,
              backgroundImageHash: hashCourseCover,
              topTags,
              bottomTags,
              variant: COURSE_TYPE_TRANSFORMER[courseType as keyof typeof COURSE_TYPE_TRANSFORMER],
              superCourseTitle: superCourse.title,
            }
          }

          if (isNewCourse) {
            bottomTags.unshift(newTag(t))
          }

          if (certified) {
            bottomTags.push({ title: cmePointsText, variant: "primary" })
          }

          if (courseType === "expertCourse") {
            bottomTags.push({ title: `${speakerTitle} ${speakerFirstName} ${speakerLastName}`, variant: "primary" })
          }

          return {
            courseId: id,
            title: courseName,
            backgroundImage: REACT_APP_STRAPI_URL + coverLandscape?.url,
            backgroundImageHash: hashCourseCoverLandscape,
            topTags,
            bottomTags,
            variant: COURSE_TYPE_TRANSFORMER[courseType as keyof typeof COURSE_TYPE_TRANSFORMER],
            superCourseTitle: superCourse.title,
          }
        }
      ) || [],
    [fetchedChannel?.courses]
  )

  const handleSubscribe = useCallback(() => {
    if (!isAuthenticated) {
      navigate(ENTRY_POINT, { replace: true })
      return
    }
    isSubscribed ? fetchUnsubscribeChannel() : fetchSubscribeChannel()
  }, [isAuthenticated, fetchedChannel, isSubscribed])

  return {
    isLoading,
    channel: fetchedChannel,
    courseCards,
    isAuthenticated,
    handleSubscribe,
    isSubscribed,
    isLoadingSubscribe: isLoadingSubscribeChannel || isLoadingUnsubscribeChannel,
  }
}
