import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// api
import {
  upload,
  UploadRequest,
  UploadResponse,
  deleteUpload,
  DeleteUploadRequest,
  DeleteUploadResponse,
  UPLOAD_KEYS,
} from "api"
// types
import { User } from "types/user"

export const useUpload = (navigateTo?: string) => {
  const navigate = useNavigate()
  const [error, setError] = useState()
  const { setUser } = useAuthenticationContext()

  const {
    mutate: _upload,
    isLoading: isLoadingUpload,
    isError: isErrorUpload,
  } = useMutation<UploadResponse, unknown, UploadRequest>({
    mutationFn: upload,
    mutationKey: [UPLOAD_KEYS.UPLOAD],
    onSuccess: ({ "0": { url, id } }) => {
      setUser((prev: User) => ({ ...prev, pictureUrl: url, pictureId: id }))

      if (navigateTo) {
        navigate(navigateTo)
      }
    },
    onError: (err: any) => {
      console.error("Upload error:", err)
      setError(err?.response?.data?.error?.message)
    },
  })

  const {
    mutate: _deleteUpload,
    isLoading: isLoadingDeleteUpload,
    isError: isErrorDeleteUpload,
  } = useMutation<DeleteUploadResponse, unknown, DeleteUploadRequest>({
    mutationFn: ({ uploadId }) => deleteUpload({ uploadId }),
    mutationKey: [UPLOAD_KEYS.DELETE_UPLOAD],
    onSuccess: () => {
      setUser((prev: User) => ({ ...prev, pictureUrl: false, pictureId: false }))

      if (navigateTo) {
        navigate(navigateTo)
      }
    },
    onError: (err: any) => {
      console.error("Delete upload error:", err)
      setError(err?.response?.data?.error?.message)
    },
  })

  return {
    upload: _upload,
    deleteUpload: _deleteUpload,
    isLoading: isLoadingUpload || isLoadingDeleteUpload,
    isError: isErrorUpload || isErrorDeleteUpload,
    error,
  }
}
