import axios from "axios"
import qs from "qs"
// constants
import { languagesTransformer } from "i18n"
// types
import { Language } from "types/i18n"
import { Pagination } from "types/api"

export type GetEpisodeQuestionsRequest = {
  episodeId: number
  locale?: Language
}

type Question = {
  id: number
  attributes: {
    question: string
    createdAt: string
    updatedAt: string
    answer?: string
  }
}

type QuestionNormalized = {
  id: number
  question: string
  createdAt: string
  updatedAt: string
  user: {
    id: number
    user_profile: {
      userFirstName: string
      userLastName: string
      userJobTitle: string
      userPicture: {
        url: string
      }
      id: number
    }
  }
  answer?: string
}

export type GetQuestionRequest = {
  questionId: number
}

export type GetQuestionResponse = {
  data: {
    id: number
    attributes: {
      episode: {
        data: {
          id: number
          attributes: {
            course: {
              data: {
                id: number
              }
            }
          }
        }
      }
    }
  }
  meta: Pagination
}

export type GetEpisodeQuestionsResponse = QuestionNormalized[]

export type AddEpisodeQuestionRequest = {
  question: string
  episodeId: number
  locale?: Language
}

export type AddEpisodeQuestionResponse = {
  data: Question
  meta: Pagination
}

export type RemoveEpisodeQuestionRequest = {
  questionId?: number
}

export type RemoveEpisodeQuestionResponse = {
  data: Question
  meta: Pagination
}

export const QUESTIONS_KEYS = Object.freeze({
  EPISODE_QUESTIONS: "episodeQuestions",
  GET_QUESTION: "getQuestion",
})

export const getEpisodeQuestions = async ({ episodeId, locale = "en" }: GetEpisodeQuestionsRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      episodeId,
    },
    { encodeValuesOnly: true }
  )

  const url = `/questions?${params}`

  const response = await axios.get<GetEpisodeQuestionsResponse>(url)

  return response.data
}

export const getQuestionById = async ({ questionId }: GetQuestionRequest) => {
  const params = qs.stringify(
    {
      fields: ["id"],
      populate: {
        episode: {
          fields: ["id"],
          populate: {
            course: {
              fields: ["id"],
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/questions/${questionId}?${params}`

  const response = await axios.get<GetQuestionResponse>(url)

  return response.data
}

export const addEpisodeQuestion = async ({ question, episodeId, locale = "en" }: AddEpisodeQuestionRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const url = `/questions`

  const response = await axios.post<AddEpisodeQuestionResponse>(url, {
    data: {
      locale: languagesTransformer[_locale],
      question,
      episode: episodeId,
    },
  })

  return response.data
}

export const removeEpisodeQuestion = async ({ questionId }: RemoveEpisodeQuestionRequest) => {
  const url = `/questions/${questionId}`

  const response = await axios.delete<RemoveEpisodeQuestionResponse>(url)

  return response.data
}
