import { Stack, Typography } from "@mui/material"
import { memo } from "react"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
// components
import TextBackground from "../TextBackground"
// types
import { PageTitleProps } from "./types"

const PageTitle = ({
  sx,
  firstRow,
  beforeBackgroundWord,
  afterBackgroundWord,
  backgroundWord,
  textBackgroundSx,
}: PageTitleProps) => {
  const { isTablet } = useMUIBreakpoints()

  const typographyVariant = isTablet ? "mobileH1" : "h1"

  return (
    <Typography sx={sx} variant={typographyVariant} component="h1" lineHeight={1.5}>
      <Stack>
        <span>{firstRow}</span>
        <div>
          {beforeBackgroundWord}{" "}
          <TextBackground variant={typographyVariant} text={backgroundWord} sx={textBackgroundSx} />
          {afterBackgroundWord}
        </div>
      </Stack>
    </Typography>
  )
}

export default memo(PageTitle)
