import { NavLink } from "react-router-dom"
// styles
import { shouldForwardProp } from "styles/helpers"
import { customStyled } from "styles/theme"
// types
import { LinkStyleProps } from "./types"

export const SLink = customStyled("div", {
  shouldForwardProp: (prop) => shouldForwardProp<LinkStyleProps>(["isActive", "sx"], prop),
})<LinkStyleProps>(
  ({
    theme: {
      palette,
      transitions: {
        duration: { standard },
        easing: { easeInOut },
      },
      typography: { h5 },
      spacing,
      breakpoints,
    },
    isActive,
  }) => ({
    ...h5,
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: `${spacing(4)} ${spacing(6)} ${spacing(4)} ${spacing(9)}`,
    transition: `all ${standard}ms ${easeInOut}`,
    color: palette.black.lacquer["400"],
    "&:hover": {
      color: palette.secondary.main,
      "& path, & rect": {
        fill: palette.secondary.main,
      },
    },
    "& path, & rect": {
      fill: palette.black.lacquer["400"],
    },
    [breakpoints.down("sm")]: {
      padding: spacing(2),
    },
    ...(isActive && {
      color: palette.secondary.main,
      "& path, & rect": {
        fill: palette.secondary.main,
      },
      "&::before": {
        content: "''",
        transition: `all ${standard}ms ${easeInOut}`,
        display: "block",
        position: "absolute",
        left: 0,
        top: 0,
        width: spacing(2),
        height: "100%",
        backgroundColor: palette.secondary.main,
        [breakpoints.down("sm")]: {
          display: "none",
        },
      },
    }),

    "& path[name='status-dot']": {
      fill: "#FF6666", // for Notification red status dot
    },
  })
)

export const StyledNavLink = customStyled(NavLink)(() => ({
  textDecoration: "none",
}))
