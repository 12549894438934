import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
// styles
import { customStyled } from "styles/theme"

export const SDesktopDatePicker = customStyled(DesktopDatePicker)<any>(({ theme: { spacing } }) => ({
  "& .MuiInputAdornment-positionEnd > .MuiIconButton-root": {
    marginRight: `-${spacing(2)}`,
  },
}))

export const SMobileDatePicker = customStyled(MobileDatePicker)<any>(() => ({}))
