import { useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
// api
import { COMMON_INFORMATION, getImprint, GetImprintResponse } from "api"
// types
import { Language } from "types/i18n"

export const useImprint = (): {
  imprint: string
  isLoading: boolean
} => {
  const { i18n } = useTranslation()

  const { data: fetchedImprint, isLoading } = useQuery<GetImprintResponse>({
    queryKey: [COMMON_INFORMATION.GET_IMPRINT, i18n.language],
    queryFn: () => getImprint({ locale: i18n.language as Language }),
  })

  const imprint = useMemo(() => fetchedImprint?.data?.attributes?.imprint || "", [fetchedImprint])

  return {
    imprint,
    isLoading,
  }
}
