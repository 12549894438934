import { AutocompleteRenderInputParams } from "@mui/material"
// components
import TextField from "shared/components/input/TextField"
import Icon from "shared/components/Icon"
// styles
import { SAutocomplete, SPaper } from "./styles"
// types
import { CustomAutoCompleteProps } from "./types"

const Autocomplete = (props: CustomAutoCompleteProps) => {
  return (
    <SAutocomplete
      disablePortal={true}
      {...props}
      popupIcon={
        <Icon
          name="chevronBottom"
          sx={[
            () => ({
              width: "20px",
            }),
          ]}
        />
      }
      clearIcon={
        <Icon
          name="closeCircle"
          sx={[
            () => ({
              width: "20px",
            }),
          ]}
        />
      }
      isOptionEqualToValue={(option: { value: unknown }, value: { value: unknown }) => option.value === value.value}
      PaperComponent={({ children }: { children: JSX.Element }) => (
        <SPaper
          sx={{
            boxShadow: "#fff inset 0 0 2px 1px",
          }}
        >
          {children}
        </SPaper>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} {...props.textFieldProps} isAutocomplete />
      )}
      // TODO: for future styling
      // sx={[
      //   ({ palette }: CustomTheme) => ({
      //     "& + .MuiAutocomplete-popper .MuiAutocomplete-option.Mui-focused": {
      //       backgroundColor: palette.secondary.main,
      //     },
      //   }),
      // ]}
    />
  )
}

export default Autocomplete
