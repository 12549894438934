// pages
import {
  Profile,
  CmeDashboard,
  Membership,
  Password,
  Terms,
  DataProtection,
  Imprint,
  Language,
  Notifications,
  Report,
} from "pages/Settings"
// constants
import {
  PROFILE,
  CME,
  MEMBERSHIP,
  PASSWORD,
  LANGUAGE,
  NOTIFICATIONS,
  REPORT,
  TERMS,
  DATA_PROTECTION,
  IMPRINT,
} from "constants/routes"
// types
import { NavigationGroup } from "./types"

export const navigationLinksProfile: NavigationGroup[] = [
  {
    tKey: "settingsLayout:navigationMenu:navGroups:account:title",
    links: [
      {
        tKey: "settingsLayout:navigationMenu:navGroups:account:navLinks:profile",
        path: PROFILE,
        iconName: "profile",
        Page: Profile,
      },
      {
        tKey: "settingsLayout:navigationMenu:navGroups:account:navLinks:cme",
        path: CME,
        iconName: "achievement",
        Page: CmeDashboard,
      },
    ],
  },
  {
    tKey: "settingsLayout:navigationMenu:navGroups:settings:title",
    links: [
      {
        tKey: "settingsLayout:navigationMenu:navGroups:settings:navLinks:membership",
        path: MEMBERSHIP,
        iconName: "star",
        Page: Membership,
      },
      {
        tKey: "settingsLayout:navigationMenu:navGroups:settings:navLinks:password",
        path: PASSWORD,
        iconName: "lock",
        Page: Password,
      },
      // Can be used in future
      // {
      //   tKey: "settingsLayout:navigationMenu:navGroups:settings:navLinks:safety",
      //   path: SAFETY,
      //   iconName: "safety",
      //   Page: Membership,
      // },
      {
        tKey: "settingsLayout:navigationMenu:navGroups:settings:navLinks:language",
        path: LANGUAGE,
        iconName: "language",
        Page: Language,
      },
      {
        tKey: "settingsLayout:navigationMenu:navGroups:settings:navLinks:notifications",
        path: NOTIFICATIONS,
        iconName: "notification",
        Page: Notifications,
      },
    ],
  },
  {
    tKey: "settingsLayout:navigationMenu:navGroups:help:title",
    links: [
      {
        tKey: "settingsLayout:navigationMenu:navGroups:help:navLinks:report",
        path: REPORT,
        iconName: "info",
        Page: Report,
      },
    ],
  },
]

export const navigationLinksPolicy: NavigationGroup[] = [
  {
    tKey: "settingsLayout:navigationMenu:navGroups:info:title",
    links: [
      {
        tKey: "settingsLayout:navigationMenu:navGroups:info:navLinks:terms",
        path: TERMS,
        iconName: "book",
        Page: Terms,
      },
      {
        tKey: "settingsLayout:navigationMenu:navGroups:info:navLinks:dataProtection",
        path: DATA_PROTECTION,
        iconName: "protection",
        Page: DataProtection,
      },
      {
        tKey: "settingsLayout:navigationMenu:navGroups:info:navLinks:imprint",
        path: IMPRINT,
        iconName: "info",
        Page: Imprint,
      },
    ],
  },
]

export const navigationLinks: NavigationGroup[] = [...navigationLinksProfile, ...navigationLinksPolicy]
