import { createRoot } from "react-dom/client"
import App from "./app/index"
import ErrorBoundary from "./app/ErrorBoundary"
import reportWebVitals from "./reportWebVitals"
import ProviderWrapper from "./provider/ProviderWrapper"
// services
import HTTPService from "services/HTTPService"
// styles
import { CssBaseline } from "@mui/material"
// styles
import "./index.css"

const container = document.getElementById("root")!
const root = createRoot(container)

const renderApp = () =>
  root.render(
    <ErrorBoundary>
      <ProviderWrapper>
        <CssBaseline />
        <App />
      </ProviderWrapper>
    </ErrorBoundary>
  )
renderApp()

HTTPService.setDefaultAxios()
HTTPService.setupInterceptors()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
