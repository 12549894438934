import { configureStore } from "@reduxjs/toolkit"

import sagaMiddleware from "./middlewares/saga"

import _reducer from "./rootReducer"
import _enhancers from "./enhancers"
import _middlewares from "./middlewares"
import _saga from "./rootSaga"
import { createReduxHistory } from "./middlewares/router"

const createStore = (
  reducer = _reducer,
  preloadedState = undefined,
  enhancers = _enhancers,
  middlewares = _middlewares,
  saga = _saga
) => {
  const store = configureStore({
    reducer,
    middleware: middlewares,
    preloadedState,
    enhancers,
    devTools: process.env.NODE_ENV !== "production",
  })

  sagaMiddleware.run(saga)

  return store
}

// creating store for redux Provider
const store = createStore()
const history = createReduxHistory(store)

export { store, history }

// default export for creating another store for tests or etc.
export default createStore
