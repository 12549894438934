import axios from "axios"

type SubmitReportRequest = {
  subject: string
  description: string
  userEmail: string
  userFullName: string
}

export const submitReport = async ({ subject, description, userEmail, userFullName }: SubmitReportRequest) => {
  const url = `/reports`

  const response = await axios.post(url, { data: { subject, description, userEmail, userFullName } })

  return response.data
}
