import { IconButton as MUIIconButton } from "@mui/material"
// styles
import { customStyled } from "styles/theme"
import { shouldForwardProp } from "styles/helpers"

export const SIconWrapper = customStyled(MUIIconButton, {
  shouldForwardProp: (prop) => shouldForwardProp([], prop),
})<any>(() => ({
  "& .MuiBox-root": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))
