import { customStyled } from "styles/theme"

export const BackgroundGradientBox = customStyled("div")(
  ({
    theme: {
      palette,
      shape: { borderRadius },
    },
  }) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "65%",
    width: "102%",
    borderRadius: `0 0 ${borderRadius(8)} ${borderRadius(8)}`,
    background: palette.gradients.linear.cardBackground,
  })
)

export const CardOverflowWrapper = customStyled("div")(
  ({
    theme: {
      zIndex: { cardOverflow },
    },
  }) => ({
    zIndex: cardOverflow,
  })
)
