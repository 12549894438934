import { useState } from "react"
// hooks
import useDownloadCertificate from "pages/Test/hooks/useDownloadCertificate"

const usePassedCmeTests = (): {
  handleDownloadCertificate: (superCourseId: number) => void
  cmeCertificateLoading: boolean
} => {
  const [currentCupperCourseId, setCurrentCupperCourse] = useState(0)

  const { cmeCertificateLoading } = useDownloadCertificate({
    superCourseId: currentCupperCourseId,
    autoFetchEnabled: true,
  })

  return {
    handleDownloadCertificate: setCurrentCupperCourse,
    cmeCertificateLoading,
  }
}

export default usePassedCmeTests
