import { Typography, Box } from "@mui/material"
import { useTranslation } from "react-i18next"
// assets
import betaBackgroundImage from "assets/images/backgrounds/beta-small.svg"
// routes
import { HOME } from "constants/routes"
// components
import Button from "shared/components/Button"
import Icon from "shared/components/Icon"
// styles
import { SFallback } from "./styles"

type Props = {
  titleKey?: string
  textKey?: string
  actionKey?: string
  buttonTextKey?: string
  navPath?: string
}

const Fallback = ({
  titleKey = "fallback:title",
  textKey = "fallback:description",
  buttonTextKey = "fallback:buttonText",
  actionKey,
  navPath = HOME,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ backgroundColor: "#010101", minHeight: "100vh" }}>
      <SFallback>
        <Box
          component="img"
          sx={{
            height: "100%",
            width: "100%",
            maxWidth: 822,
          }}
          alt="Beta Background"
          src={betaBackgroundImage}
        />
        <Box mt={11}>
          <Icon name="logo" sx={{ mr: 3 }} />
          <Icon name="logoName" sx={{ "& svg": { height: "30px", width: "100px", transform: "translateY(3px)" } }} />
        </Box>
        <Typography
          component="h1"
          sx={[
            ({ breakpoints, spacing }) => ({
              marginTop: spacing(4),
              fontSize: "30px",
              fontWeight: 800,
              maxWidth: 820,
              textAlign: "center",

              [breakpoints.down("sm")]: {
                fontSize: "24px",
              },
            }),
          ]}
          color="#fff"
        >
          {t(titleKey)}
        </Typography>
        <Typography
          component="p"
          sx={[
            ({ breakpoints, spacing }) => ({
              marginTop: spacing(6),
              marginBottom: spacing(5),
              fontSize: "20px",
              maxWidth: 820,
              textAlign: "center",

              [breakpoints.down("sm")]: {
                fontSize: "18px",
                marginTop: spacing(8),
                marginBottom: spacing(8),
              },
            }),
          ]}
        >
          {t(textKey)}
        </Typography>

        {actionKey && (
          <Typography
            component="p"
            sx={[
              ({ breakpoints, spacing }) => ({
                marginTop: spacing(6),
                marginBottom: spacing(5),
                fontSize: "20px",
                maxWidth: 820,
                textAlign: "center",

                [breakpoints.down("sm")]: {
                  fontSize: "18px",
                  marginTop: spacing(8),
                  marginBottom: spacing(8),
                },
              }),
            ]}
          >
            {t(actionKey)}
          </Typography>
        )}
        <Button
          href={navPath}
          color="secondary"
          variant="contained"
          sx={[
            ({ breakpoints }) => ({
              width: "437px",
              height: "48px",
              [breakpoints.down("sm")]: {
                width: "100%",
              },
            }),
          ]}
        >
          {t(buttonTextKey)}
        </Button>
      </SFallback>
    </Box>
  )
}

export default Fallback
