import { Box, Typography } from "@mui/material"
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
import { useTranslation } from "react-i18next"
// components
import SimpleLink from "shared/components/SimpleLink"
// constants
import { links } from "./constants"
// types
import { FooterProps } from "./types"

const Footer = ({ limited = false }: FooterProps) => {
  const { t } = useTranslation()
  const { isTablet } = useMUIBreakpoints()

  return (
    <Box
      sx={{
        ...(limited && {
          maxWidth: "1440px",
          width: "100%",
          margin: "0 auto",
        }),
      }}
    >
      <Box
        component="footer"
        sx={[
          {
            display: "flex",
            padding: 12,
          },
          isTablet && {
            padding: 7,
          },
        ]}
      >
        {links.map(({ localeKey, href }) => (
          <SimpleLink
            sx={[
              {
                mr: 8,
              },
              isTablet && {
                mr: 5,
              },
            ]}
            href={href}
            key={localeKey}
          >
            {t(localeKey)}
          </SimpleLink>
        ))}
        <Typography variant={isTablet ? "mobileH4" : "h5"} color="black.lacquer.400">
          {t("mainLayout:footer:links:copyright")}
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
