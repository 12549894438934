import { useTranslation } from "react-i18next"
import i18n from "i18n"
// hooks
import { useToggleLanguage } from "./hooks/useToggleLanguage"
// components
import Button from "shared/components/Button"
import { Autocomplete } from "shared/components/input"
// styles
import { SLanguageButtonWrapper } from "./styles"
import { SInnerWrapper } from "../styles"

const Language = () => {
  const { t } = useTranslation()

  const { languagesArray, selectedLng, handleChangeSelectedLng, handleChangeLng } = useToggleLanguage()

  return (
    <SInnerWrapper>
      <Autocomplete
        sx={[
          (theme) => ({
            mt: 13,
            mb: 11,
            [theme.breakpoints.down("xs")]: {
              mt: 7,
            },
          }),
        ]}
        options={languagesArray}
        textFieldProps={{ label: t("settings:language:dropdownTitle") }}
        onChange={handleChangeSelectedLng}
        value={selectedLng}
        filterSelectedOptions
        disableClearable
      />
      <SLanguageButtonWrapper>
        <Button
          onClick={handleChangeLng}
          disabled={selectedLng.value === i18n.language}
          variant="contained"
          color="secondary"
          fullWidth
        >
          {t(`settings:language:buttonTitle`)}
        </Button>
      </SLanguageButtonWrapper>
    </SInnerWrapper>
  )
}

export default Language
