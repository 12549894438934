import axios from "axios"
import qs from "qs"

type GetCheckoutSessionRequest = {
  userId: number
  courseId: number
}

export const STRIPE_KEYS = {
  GET_CHECKOUT_SESSION: "getCheckoutSession",
}

export const getCheckoutSession = async ({ userId, courseId }: GetCheckoutSessionRequest) => {
  const params = qs.stringify(
    {
      user: userId,
      course: courseId,
    },
    { encodeValuesOnly: true }
  )

  const url = `/create-stripe-checkout-session?${params}`

  const response = await axios.get<any>(url)

  return response.data
}
