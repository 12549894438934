// constants
import { TERMS, DATA_PROTECTION, IMPRINT } from "constants/routes"
// types
import { FooterLink } from "./types"

export const links: FooterLink[] = [
  { localeKey: "mainLayout:footer:links:agb", href: TERMS },
  { localeKey: "mainLayout:footer:links:privacy", href: DATA_PROTECTION },
  { localeKey: "mainLayout:footer:links:imprint", href: IMPRINT },
]
