import axios from "axios"
import qs from "qs"
// types
import { Pagination } from "types/api"

export type NotificationActionType = "Navigate_to_course" | "Navigate_to_question" | "Navigate_to_membership" | "null"

export type GetUserNotificationsResponse = {
  data: {
    id: number
    attributes: {
      title: string
      description: string
      actionContentId: number
      isRead: boolean
      createdAt: string
      notificationTemplate: {
        data: {
          attributes: {
            actionType: NotificationActionType
            icon: {
              data: {
                attributes: {
                  url: string
                }
              }
            }
          }
        }
      }
    }
  }[]
  meta: Pagination
}

export type GetUserUnreadNotificationResponse = {
  data: {
    attributes: {
      isRead: boolean
    }
  }[]
  meta: Pagination
}

export type ReadUserNotificationRequest = {
  notificationId: number
}

export const USER_NOTIFICATIONS = {
  GET_USER_NOTIFICATIONS: "getUserNotifications",
  GET_USER_UNREAD_NOTIFICATION: "getUserUnreadNotification",
}

export const getUserNotifications = async () => {
  const params = qs.stringify(
    {
      locale: "all",
      populate: {
        notificationTemplate: {
          fields: ["actionType", "locale"],
          populate: {
            icon: {
              fields: ["url"],
            },
          },
        },
      },
      sort: { createdAt: "DESC" },
    },
    { encodeValuesOnly: true }
  )

  const url = `/user-notifications?${params}`

  const response = await axios.get<GetUserNotificationsResponse>(url)

  return response.data
}

export const readUserNotificationById = async ({ notificationId }: ReadUserNotificationRequest) => {
  const url = `/user-notifications/${notificationId}`

  const response = await axios.put(url, { data: { isRead: true } })

  return response.data
}

export const getUserUnreadNotification = async () => {
  const params = qs.stringify(
    {
      locale: "all",
      fields: ["isRead"],
      filters: {
        isRead: {
          $eqi: false,
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/user-notifications/?${params}`

  const response = await axios.get<GetUserUnreadNotificationResponse>(url)

  return response.data
}
