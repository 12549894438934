import { useState, useCallback } from "react"

type UseToggleVisibility = {
  isDefaultVisible?: boolean | string | number
}

export const useToggleVisibility = ({ isDefaultVisible }: UseToggleVisibility = {}) => {
  const [isVisible, setIsVisible] = useState(!!isDefaultVisible)

  const handleToggle = useCallback(() => setIsVisible((v) => !v), [])
  const handleShow = useCallback(() => setIsVisible(true), [])
  const handleHide = useCallback(() => setIsVisible(false), [])

  return {
    isVisible,
    handleShow,
    handleHide,
    handleToggle,
    handleClickAway: handleHide,
  }
}
