// styles
import { customStyled } from "styles/theme"

export const SFallback = customStyled("div")(({ theme: { palette, spacing, breakpoints } }) => ({
  color: palette.white.main,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: `${spacing(14)} ${spacing(13)}`,

  [breakpoints.down("xs")]: {
    padding: `${spacing(12)} ${spacing(7)}`,
  },
}))
