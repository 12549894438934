import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
// routes
import { coursePath } from "constants/routes"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
// components
import Icon from "shared/components/Icon"
import NavLink from "shared/components/NavLink"
// constants
import { REACT_APP_STRAPI_URL } from "constants/endpoints"
// styles
import {
  SWatchedCoursesWrapper,
  SDescriptionWrapper,
  SWatchedCourseWrapper,
  SWatchedCourseContentWrapper,
  CoursePreviewImage,
} from "./styles"
// types
import { NormalizedCourses } from "../../hooks/utils"

type WatchedCoursesProps = {
  watchedCoursesByYears: NormalizedCourses
  onCloseDetailScreen: () => void
}

const WatchedCourses = ({ watchedCoursesByYears, onCloseDetailScreen }: WatchedCoursesProps) => {
  const { t } = useTranslation()
  const { isTablet } = useMUIBreakpoints()

  return (
    <SWatchedCoursesWrapper>
      <Icon
        name="chevronLeftLong"
        withBackground
        backgroundVariant="primary"
        sx={[
          (theme) => ({
            cursor: "pointer",
            width: "48px",
            height: "48px",
            position: "absolute",
            top: theme.spacing(11),
            left: theme.spacing(9),
            [theme.breakpoints.down("md")]: {
              position: "fixed",
              zIndex: theme.zIndex.overAll,
              top: theme.spacing(3),
              left: theme.spacing(4),
              backgroundColor: theme.palette.black.lacquer[950],
            },
          }),
        ]}
        onClick={onCloseDetailScreen}
      />
      <Typography
        component="h4"
        variant={isTablet ? "mobileH2" : "h4"}
        sx={[
          (theme) => ({
            mt: theme.spacing(14),
            mb: theme.spacing(7),
            width: "437px",
            [theme.breakpoints.down("md")]: {
              mt: theme.spacing(9),
            },
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }),
        ]}
      >
        {t("settings:cme:watchedCourses:title")}
      </Typography>
      <SDescriptionWrapper>
        <Typography component="h4" variant={isTablet ? "mobileH2" : "h4"}>
          {Object.values(watchedCoursesByYears).flat().length
            ? t("settings:cme:watchedCourses:description")
            : t("settings:cme:watchedCourses:descriptionEmptyState")}
        </Typography>
      </SDescriptionWrapper>
      <div>
        {Object.entries(watchedCoursesByYears).map(([year, watchedCoursesByYear]) => (
          <div key={year}>
            <Typography
              component="h6"
              variant={isTablet ? "mobileH3" : "h6"}
              sx={[
                (theme) => ({
                  color: theme.palette.black.lacquer[400],
                  mt: theme.spacing(11),
                  mb: theme.spacing(4),
                  width: "437px",
                  [theme.breakpoints.down("sm")]: {
                    mt: theme.spacing(7),
                    width: "100%",
                  },
                }),
              ]}
            >
              {year}
            </Typography>
            {watchedCoursesByYear.map(
              ({ courseName, courseWatchedDate, cmePointsText, specialtiesStr, cover, courseId }) => (
                <NavLink to={coursePath(courseId)} key={courseName + specialtiesStr} sxLink={{ p: 0 }}>
                  <SWatchedCourseWrapper>
                    <CoursePreviewImage previewImage={REACT_APP_STRAPI_URL + cover?.data.attributes.url} />
                    <SWatchedCourseContentWrapper>
                      <Typography component="h5" variant={isTablet ? "mobileH4" : "h5"} mb={3} color="white.main">
                        {courseName}
                      </Typography>
                      <Typography
                        component="span"
                        variant={isTablet ? "mobileDescription" : "description"}
                        color="#9E9E9E"
                      >
                        {courseWatchedDate} &#x2022; {cmePointsText} &#x2022; {specialtiesStr}
                      </Typography>
                    </SWatchedCourseContentWrapper>
                  </SWatchedCourseWrapper>
                </NavLink>
              )
            )}
          </div>
        ))}
      </div>
    </SWatchedCoursesWrapper>
  )
}

export default WatchedCourses
