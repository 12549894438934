import { Autocomplete as MUIAutocomplete, Paper as MUIPaper } from "@mui/material"
// styles
import { customStyled } from "styles/theme"
import { shouldForwardProp } from "styles/helpers"

export const SAutocomplete = customStyled(MUIAutocomplete, {
  shouldForwardProp: (prop) => shouldForwardProp(["textFieldProps"], prop),
})<any>(({ theme: { palette, spacing } }) => ({
  "&.MuiAutocomplete-root": {
    width: "100%",

    ".MuiFilledInput-root": {
      paddingTop: spacing(5),
    },
  },

  ".MuiAutocomplete-endAdornment": {
    height: "28px",
  },

  ".MuiAutocomplete-clearIndicator": {
    padding: `0 ${spacing(2)}`,
    svg: {
      fill: palette.black.lacquer[400],
    },
    "& > div": {
      overflow: "hidden",
    },
  },

  ".MuiAutocomplete-popupIndicator .MuiBox-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))

export const SPaper = customStyled(MUIPaper)(({ theme: { palette, shape } }) => ({
  backgroundColor: palette.black.lacquer[950],
  borderRadius: shape.borderRadius(3),
}))
