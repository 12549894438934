import { customStyled } from "styles/theme"

export const SInnerWrapper = customStyled("div")(({ theme: { spacing, breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 auto",
  width: "437px",
  paddingBottom: spacing(12),
  [breakpoints.down("xs")]: {
    padding: spacing(5),
    paddingBottom: spacing(11),
    width: "100%",
  },
}))
