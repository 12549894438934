import { useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
// api
import { updateUserData, UpdateUserDataRequest, UpdateUserDataResponse, USER_KEYS } from "api"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"

export const useOnboarding = (navigateTo?: string) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [error, setError] = useState()
  const { user } = useAuthenticationContext()

  const {
    mutate: _updateUserData,
    isLoading,
    isError,
  } = useMutation<UpdateUserDataResponse, unknown, UpdateUserDataRequest>({
    mutationFn: updateUserData,
    onSuccess: () => {
      queryClient.invalidateQueries([USER_KEYS.STRAPI_USER])

      if (navigateTo) {
        navigate(navigateTo)
      }
    },
    onError: (err: any) => {
      setError(err?.response?.data?.error?.message)
    },
  })

  return { updateUserData: _updateUserData, isLoading, isError, error, user }
}
