import { Box, Typography } from "@mui/material"
// types
import { TextBackgroundProps } from "./types"

const TextBackground = ({ variant, text, sx }: TextBackgroundProps) => {
  return (
    <Box
      sx={[
        ({ breakpoints }) => ({
          padding: "6px 0",
          position: "relative",
          overflowY: "hidden",
          overflowX: "hidden",
          width: "max-content",
          maxWidth: "100%",
          display: "inline-block",
          top: "26px",
          marginTop: "-30px",
          [breakpoints.down("sm")]: {
            top: "16px",
            maxWidth: "100%",
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box sx={{ position: "relative", padding: "0 25px 0 15px", display: "inline-block" }}>
        <Typography
          style={{
            opacity: 0,
          }}
          variant={variant}
          component="div"
        >
          {text}
        </Typography>
        <Box
          color="green.mid"
          sx={[
            ({ breakpoints }) => ({
              overflow: "hidden",
              display: "inline-block",
              padding: "14px 20px",
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              [breakpoints.down("sm")]: {
                padding: "10px 20px",
              },
              "&:before": {
                zIndex: 2,
                content: "''",
                display: "block",
                width: "200%",
                height: "140%",
                left: "-50%",
                top: "-122%",
                borderRadius: "50%",
                background: "black",
                position: "absolute",
              },
              "&:after": {
                zIndex: 1,
                content: "''",
                display: "block",
                width: "180%",
                height: "90%",
                left: "-40%",
                bottom: "7px",
                borderRadius: "50%",
                background: "linear-gradient(to right, #16F3A2 20%, #00F0FF)",
                position: "absolute",
                [breakpoints.down("sm")]: {
                  height: "89%",
                },
              },
            }),
          ]}
        >
          <Box
            sx={{
              background: "black",
              position: "absolute",
              width: "20px",
              height: "130%",
              top: "-15%",
              left: "-10px",
              transform: "skew(-10deg)",
              zIndex: 2,
            }}
          />
          <Box
            sx={{
              background: "black",
              position: "absolute",
              width: "20px",
              height: "130%",
              top: "-15%",
              right: "-10px",
              transform: "skew(10deg)",
              zIndex: 2,
            }}
          />
          <Typography
            sx={{
              zIndex: 2,
              position: "absolute",
            }}
            variant={variant}
            component="p"
          >
            {text}
          </Typography>
          <Typography
            style={{
              opacity: 0,
            }}
            variant={variant}
            component="p"
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default TextBackground
