import { useContext } from "react"
// types
import AuthenticationContext, { TAuthenticationContext } from "./context"

const useAuthenticationContext = () => {
  const context = useContext<TAuthenticationContext>(AuthenticationContext)

  if (!context) {
    throw new Error("`AuthenticationContext` should be used within a `AuthenticationProvider`")
  }

  return context
}

export default useAuthenticationContext
