import { useCallback } from "react"
import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
import usePassedCmeTests from "./hooks/usePassedCmeTests"
// components
import Icon from "shared/components/Icon"
// styles
import {
  SPassedCmeTestsWrapper,
  SDescriptionWrapper,
  SPassedCmeTestWrapper,
  SPassedCmeTestContentWrapper,
} from "./styles"
// types
import { NormalizedCourses } from "../../hooks/utils"

type PassedCmeTestsProps = {
  passedCmeTestCoursesByYears: NormalizedCourses
  onCloseDetailScreen: () => void
}

const PassedCmeTests = ({ passedCmeTestCoursesByYears, onCloseDetailScreen }: PassedCmeTestsProps) => {
  const { t } = useTranslation()
  const { isTablet } = useMUIBreakpoints()

  const { handleDownloadCertificate: onDownloadCertificate, cmeCertificateLoading } = usePassedCmeTests()

  const handleDownloadCertificate = useCallback(
    (superCourseId: number) => {
      if (!cmeCertificateLoading) onDownloadCertificate(superCourseId)
    },
    [cmeCertificateLoading]
  )

  return (
    <SPassedCmeTestsWrapper>
      <Icon
        name="chevronLeftLong"
        withBackground
        backgroundVariant="primary"
        sx={[
          (theme) => ({
            cursor: "pointer",
            width: "48px",
            height: "48px",
            position: "absolute",
            top: theme.spacing(11),
            left: theme.spacing(9),
            [theme.breakpoints.down("md")]: {
              position: "fixed",
              zIndex: theme.zIndex.overAll,
              top: theme.spacing(3),
              left: theme.spacing(4),
              backgroundColor: theme.palette.black.lacquer[950],
            },
          }),
        ]}
        onClick={onCloseDetailScreen}
      />
      <Typography
        component="h4"
        variant={isTablet ? "mobileH2" : "h4"}
        sx={[
          (theme) => ({
            mt: theme.spacing(14),
            mb: theme.spacing(7),
            width: "437px",
            [theme.breakpoints.down("md")]: {
              mt: theme.spacing(9),
            },
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }),
        ]}
      >
        {t("settings:cme:passedTests:title")}
      </Typography>
      {!Object.values(passedCmeTestCoursesByYears).flat().length && (
        <SDescriptionWrapper>
          <Typography component="h4" variant={isTablet ? "mobileH2" : "h4"}>
            {t("settings:cme:passedTests:descriptionEmptyState")}
          </Typography>
        </SDescriptionWrapper>
      )}
      <div>
        {Object.entries(passedCmeTestCoursesByYears).map(([year, passedCmeTestCoursesByYear]) => {
          if (!passedCmeTestCoursesByYear?.length) return null

          return (
            <div key={year}>
              <Typography
                component="h6"
                variant={isTablet ? "mobileH3" : "h6"}
                sx={[
                  (theme) => ({
                    color: theme.palette.black.lacquer[400],
                    mt: theme.spacing(11),
                    mb: theme.spacing(4),
                    width: "437px",
                    [theme.breakpoints.down("sm")]: {
                      mt: theme.spacing(7),
                      width: "100%",
                    },
                  }),
                ]}
              >
                {year}
              </Typography>
              {passedCmeTestCoursesByYear.map(
                ({ courseName, cmeTestPassedDate, cmePointsText, specialtiesStr, superCourseId }) => (
                  <SPassedCmeTestWrapper key={courseName + specialtiesStr}>
                    <SPassedCmeTestContentWrapper>
                      <Typography component="h5" variant={isTablet ? "mobileH4" : "h5"} mb={3}>
                        {courseName}
                      </Typography>
                      <Typography
                        component="span"
                        variant={isTablet ? "mobileDescription" : "description"}
                        color="#9E9E9E"
                      >
                        {cmeTestPassedDate} &#x2022; {cmePointsText} &#x2022; {specialtiesStr}
                      </Typography>
                    </SPassedCmeTestContentWrapper>
                    <Icon
                      name="download"
                      withBackground
                      backgroundVariant="dark"
                      sx={[
                        () => ({
                          cursor: "pointer",
                          path: {
                            fill: "#16F3A2",
                          },
                        }),
                      ]}
                      onClick={() => handleDownloadCertificate(superCourseId)}
                    />
                  </SPassedCmeTestWrapper>
                )
              )}
            </div>
          )
        })}
      </div>
    </SPassedCmeTestsWrapper>
  )
}

export default PassedCmeTests
