import { Switch } from "@mui/material"
// styles
import { SFormControlLabel, SSwitch } from "./styles"
// types
import { CustomSwitchProps } from "./types"

const SwitchButton = (props: CustomSwitchProps) => {
  const { label, labelPlacement = "start" } = props

  return (
    <SFormControlLabel
      control={
        <SSwitch>
          <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple sx={{ m: 1 }} {...props} />
        </SSwitch>
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

export default SwitchButton
