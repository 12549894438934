import { FormControlLabel as MUIFormControlLabel } from "@mui/material"
// styles
import { customStyled } from "styles/theme"
import { shouldForwardProp } from "styles/helpers"
// types
import { CustomSwitchProps } from "./types"

export const SFormControlLabel = customStyled(MUIFormControlLabel, {
  shouldForwardProp: (prop) => shouldForwardProp([], prop),
})<CustomSwitchProps>(
  ({
    theme: {
      palette,
      spacing,
      typography: { h5, mobileH3 },
      breakpoints,
    },
  }) => ({
    margin: 0,
    padding: `${spacing(5)} 0`,
    width: "100%",

    "& .MuiFormControlLabel-label, & .MuiFormControlLabel-label.Mui-disabled": {
      ...h5,
      color: palette.white.main,
      marginRight: spacing(6),
      width: "100%",
      lineHeight: "1.2",

      [breakpoints.down("md")]: {
        ...mobileH3,
      },
    },
  })
)

export const SSwitch = customStyled("div", {
  shouldForwardProp: (prop) => shouldForwardProp([], prop),
})(({ theme: { palette, transitions } }) => ({
  "& .MuiSwitch-root": {
    width: 40,
    height: 24,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 4,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#010101",
        "& + .MuiSwitch-track": {
          backgroundColor: palette.green.hyperpop[500],
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "gray",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      height: 16,
    },
    "& .MuiSwitch-track": {
      borderRadius: 24 / 2,
      backgroundColor: palette.black.lacquer[500],
      opacity: 1,
      transition: transitions.easing,
    },
  },
}))
