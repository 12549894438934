import { createContext } from "react"
// types
import { User } from "types/user"

export type TAuthenticationContext = {
  setAuthToken: (token: string) => void
  handleRemoveAuthToken: () => void
  user: User
  authToken: string | null
  setUser: any
  refetchUser: () => void
}

const AuthenticationContext = createContext({
  user: {} as User,
  authToken: "",
  setAuthToken: () => {},
  handleRemoveAuthToken: () => {},
  setUser: () => {},
  refetchUser: () => {},
} as TAuthenticationContext)

export default AuthenticationContext
