import { Alert, Snackbar, Typography } from "@mui/material"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import * as yup from "yup"
// hooks
import useChangePassword from "./hooks/useChangePassword"
import useToggleSnackbar from "hooks/useToggleSnackbar"
// components
import Button from "shared/components/Button"
import { TextField } from "shared/components/input"
// utils
import { passwordValidator } from "utils/validators"
// styles
import { SInnerWrapper } from "../styles"

interface FormData {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

const validator = (t: TFunction) =>
  yup
    .object()
    .shape({
      currentPassword: yup
        .string()
        .required(t("settings:password:inputFields:currentPassword:errors:required"))
        .min(8, t("settings:password:inputFields:currentPassword:errors:min")),
      passwordConfirmation: yup
        .string()
        .required(t("settings:password:inputFields:password:errors:required"))
        .min(8, t("settings:password:inputFields:password:errors:min"))
        .oneOf([yup.ref("password"), null], t("settings:password:inputFields:passwordConfirmation:errors:match")),
    })
    .concat(passwordValidator(t))

const ForgotPassword = () => {
  const { t } = useTranslation()
  const { resetPassword, isLoading, isSuccess, isError, error } = useChangePassword()
  const { open, handleClose } = useToggleSnackbar(isError)

  const { handleSubmit, formState, control, reset, watch, trigger } = useForm<FormData>({
    resolver: yupResolver(validator(t)),
    defaultValues: {
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    },
    reValidateMode: "onChange",
  })

  const watchedPass = watch("password")
  const watchedConfirmPass = watch("passwordConfirmation")

  useEffect(() => {
    const passwordSchema = passwordValidator(t)

    if (!watchedPass || !passwordSchema.isValidSync({ password: watchedPass })) return

    trigger("passwordConfirmation")
  }, [watchedPass, watchedConfirmPass, trigger, t])

  const handleFormSubmit = ({ currentPassword, password, passwordConfirmation }: FormData) => {
    resetPassword({ currentPassword, password, passwordConfirmation })
    reset()
  }

  return (
    <SInnerWrapper>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Controller
          name="currentPassword"
          control={control}
          render={({
            field,
            formState: {
              errors: { currentPassword },
            },
          }) => (
            <TextField
              onCopy={(e) => e.preventDefault()}
              type="password"
              inputProps={field}
              error={!!currentPassword?.message}
              helperText={currentPassword?.message}
              sx={{ mb: 4, mt: 13 }}
              placeholder={t("settings:password:inputFields:currentPassword:placeholder")}
              infoText={t("settings:password:inputFields:currentPassword:infoText")}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({
            field,
            formState: {
              errors: { password },
            },
          }) => (
            <TextField
              onCopy={(e) => e.preventDefault()}
              type="password"
              inputProps={field}
              error={!!password?.message}
              helperText={password?.message}
              sx={{ mb: 4 }}
              placeholder={t("settings:password:inputFields:password:placeholder")}
              infoText={t("settings:password:inputFields:password:infoText")}
            />
          )}
        />
        <Controller
          name="passwordConfirmation"
          control={control}
          render={({
            field,
            formState: {
              errors: { passwordConfirmation },
            },
          }) => (
            <TextField
              onPaste={(e) => e.preventDefault()}
              type="password"
              inputProps={field}
              error={!!passwordConfirmation?.message}
              helperText={passwordConfirmation?.message}
              sx={{ mb: 4 }}
              placeholder={t("settings:password:inputFields:passwordConfirmation:placeholder")}
              infoText={t("settings:password:inputFields:passwordConfirmation:infoText")}
            />
          )}
        />
        <Button
          type="submit"
          disabled={!!Object.keys(formState.errors).length || isLoading}
          color="secondary"
          fullWidth
          variant="contained"
        >
          {t("settings:password:submitButtonText")}
        </Button>
      </form>
      {isSuccess && (
        <Typography sx={{ maxWidth: 438 }} mt={3} variant="h5" color="secondary.main">
          {t("settings:password:success")}
        </Typography>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={error}
        onClose={handleClose}
      >
        <Alert severity="error" sx={{ width: "100%" }} onClose={handleClose} closeText="Close">
          {error}
        </Alert>
      </Snackbar>
    </SInnerWrapper>
  )
}

export default ForgotPassword
