import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import remarkBreaks from "remark-breaks"
// hooks
import { useImprint } from "./hooks/useImprint"
// styles
import { SImprint } from "./styles"

const Imprint = () => {
  const { imprint } = useImprint()

  return (
    <SImprint>
      <ReactMarkdown remarkPlugins={[remarkGfm, remarkBreaks]}>{imprint}</ReactMarkdown>
    </SImprint>
  )
}

export default Imprint
