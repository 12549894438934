import axios from "axios"
import qs from "qs"
// constants
import { languagesTransformer } from "i18n"
// types
import { Language } from "types/i18n"
import { Speaker } from "types/speaker"

type GetSpeakerRequest = {
  speakerId: string
  locale: Language
}

export type GetSpeakerResponse = Speaker

export const SPEAKER_KEYS = {
  SPEAKER: "speaker",
}

export const getSpeaker = async ({ speakerId, locale = "en" }: GetSpeakerRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      fields: ["speakerFirstName", "speakerLastName", "speakerTitle", "speakerPictureHash", "expertFor"],
      populate: {
        speakerPicture: {
          fields: ["ext", "url"],
        },
        publication: {
          populate: {
            publicationComponent: {
              fields: ["id", "publicationYear", "publicationType", "publicationMetainfo", "publicationName"],
            },
          },
        },
        curriculumVitae: {
          populate: {
            curriculumVitaeFields: {
              fields: ["id", "cvTimePeriod", "cvWorkStation", "cvLocation", "cvJobPosition", "cvDescription"],
            },
          },
        },
        courses: {
          fields: [
            "publishedAt",
            "courseType",
            "courseLength",
            "courseLengthText",
            "cmePoints",
            "cmePointsText",
            "courseName",
            "certified",
            "hashCourseCover",
            "hashCourseCoverLandscape",
          ],
          populate: {
            episodes: {
              fields: ["episodeLength", "episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover"],
              populate: {
                episodeCover: {
                  fields: ["ext", "url"],
                },
              },
            },
            cover: {
              fields: ["ext", "url"],
            },
            coverLandscape: {
              fields: ["ext", "url"],
            },
            speaker: {
              fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/speakers/${speakerId}?${params}`

  const response = await axios.get<GetSpeakerResponse>(url)

  return response.data
}
