import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import remarkBreaks from "remark-breaks"
// hooks
import { useDataProtection } from "./hooks/useDataProtection"
// styles
import { SDataProtection } from "./styles"

const DataProtection = () => {
  const { dataProtection } = useDataProtection()

  return (
    <SDataProtection>
      <ReactMarkdown remarkPlugins={[remarkGfm, remarkBreaks]}>{dataProtection}</ReactMarkdown>
    </SDataProtection>
  )
}

export default DataProtection
