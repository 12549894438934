import axios from "axios"
import qs from "qs"
// constants
import { languagesTransformer } from "i18n"
// types
import { Language } from "types/i18n"
import { Pagination } from "types/api"

export type GetTermsOfUseRequest = {
  locale?: Language
}

export type GetTermsOfUseResponse = {
  data: {
    id: number
    attributes: {
      termsOfUse: string
    }
  }
  meta: Pagination
}

export type GetDataProtectionRequest = {
  locale?: Language
}

export type GetDataProtectionResponse = {
  data: {
    id: number
    attributes: {
      dataProtection: string
    }
  }
  meta: Pagination
}

export type GetImprintRequest = {
  locale?: Language
}

export type GetImprintResponse = {
  data: {
    id: number
    attributes: {
      imprint: string
    }
  }
  meta: Pagination
}

export const COMMON_INFORMATION = {
  GET_TERMS_OF_USE: "getTermsOfUse",
  GET_DATA_PROTECTION: "getDataProtection",
  GET_IMPRINT: "getImprint",
}

export const getTermsOfUse = async ({ locale = "en" }) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      fields: ["termsOfUse"],
    },
    { encodeValuesOnly: true }
  )

  const url = `/common-information/?${params}`

  const response = await axios.get<GetTermsOfUseResponse>(url)

  return response.data
}

export const getDataProtection = async ({ locale = "en" }) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      fields: ["dataProtection"],
    },
    { encodeValuesOnly: true }
  )

  const url = `/common-information/?${params}`

  const response = await axios.get<GetDataProtectionResponse>(url)

  return response.data
}

export const getImprint = async ({ locale = "en" }) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      fields: ["imprint"],
    },
    { encodeValuesOnly: true }
  )

  const url = `/common-information/?${params}`

  const response = await axios.get<GetImprintResponse>(url)

  return response.data
}
