import axios from "axios"
import qs from "qs"
// constants
import { languagesTransformer } from "i18n"
// types
import { Language } from "types/i18n"
import { Pagination } from "types/api"
import {
  Course,
  CourseAttributes,
  CourseTypes,
  Episode,
  EpisodeAttributes,
  Speaker,
  SpeakerAttributes,
} from "types/courses"

export type GetFavouriteCoursesRequest = {
  locale?: Language
}

type Favorite = {
  id: number
  attributes: {
    createdAt: string
    updatedAt: string
  }
}

type FavouriteCourse = {
  id: number
  attributes: {
    superCourse: {
      data: {
        id: number
        attributes: {
          courses: {
            data: Course<
              Omit<
                CourseAttributes<{ id: number }[], Speaker<Omit<SpeakerAttributes, "speakerPicture">>>,
                "coursePartner" | "courseLength" | "cmePoints" | "courseDescription" | "episodes"
              >
            >[]
          }
        }
      }
    }
  }
}

export type GetFavouriteCoursesResponse = {
  data: FavouriteCourse[]
  meta: Pagination
}

export type AddFavouriteCourseRequest = {
  courseId: number
}

export type AddFavouriteCourseResponse = {
  data: Favorite
  meta: Pagination
}

export type RemoveFavouriteCourseRequest = {
  favouriteId?: number
}

export type RemoveFavouriteCourseResponse = {
  data: Favorite
  meta: Pagination
}

export type GetFavouriteEpisodesRequest = {
  locale?: Language
}

type FavouriteEpisode = {
  id: number
  attributes: {
    superEpisode: {
      data: {
        id: number
        attributes: {
          episodes: {
            data: Episode<
              Omit<
                EpisodeAttributes,
                | "episodeNumber"
                | "kalturaVideoId"
                | "episodeCoverLandscape"
                | "localizations"
                | "hashEpisodeCoverLandscape"
                | "episodeLength"
              > & {
                course: {
                  data: {
                    id: number
                    attributes: {
                      courseType: CourseTypes
                    }
                  }
                }
              }
            >[]
          }
        }
      }
    }
  }
}

export type GetFavouriteEpisodesResponse = {
  data: FavouriteEpisode[]
  meta: Pagination
}

export type AddFavouriteEpisodeRequest = {
  episodeId: number
}

export type AddFavouriteEpisodeResponse = {
  data: Favorite
  meta: Pagination
}

export type RemoveFavouriteEpisodeRequest = {
  favouriteId: number
}

export type RemoveFavouriteEpisodeResponse = {
  data: Favorite
  meta: Pagination
}

export const FAVOURITES_KEYS = Object.freeze({
  FAVOURITE_COURSES: "favouriteCourses",
  FAVOURITE_EPISODES: "favouriteEpisodes",
})

export const getFavouriteCourses = async ({ locale = "en" }: GetFavouriteCoursesRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      sort: { id: "DESC" },
      locale: languagesTransformer[_locale],
      populate: {
        superCourse: {
          populate: {
            courses: {
              fields: [
                "publishedAt",
                "courseType",
                "courseLengthText",
                "cmePointsText",
                "courseName",
                "certified",
                "hashCourseCover",
                "hashCourseCoverLandscape",
                "locale",
              ],
              populate: {
                cover: {
                  fields: ["ext", "url"],
                },
                coverLandscape: {
                  fields: ["ext", "url"],
                },
                speaker: {
                  fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
                },
              },
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/super-course-favourites?${params}`

  const response = await axios.get<GetFavouriteCoursesResponse>(url)

  return response.data
}

export const addFavouriteCourse = async ({ courseId }: AddFavouriteCourseRequest) => {
  const url = `/super-course-favourites`

  const response = await axios.post<AddFavouriteCourseResponse>(url, {
    data: {
      superCourse: courseId,
    },
  })

  return response.data
}

export const removeFavouriteCourse = async ({ favouriteId }: RemoveFavouriteCourseRequest) => {
  const url = `/super-course-favourites/${favouriteId}`

  const response = await axios.delete<RemoveFavouriteCourseResponse>(url)

  return response.data
}

export const getFavouriteEpisodes = async ({ locale = "en" }: GetFavouriteEpisodesRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],

      populate: {
        fields: ["id"],
        superEpisode: {
          fields: ["id"],
          populate: {
            episodes: {
              fields: ["episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover", "locale"],
              populate: {
                episodeCover: {
                  fields: ["ext", "url"],
                },
                course: {
                  fields: ["id", "courseType"],
                },
                superEpisode: {
                  fields: ["id"],
                },
              },
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/super-episode-favourites?${params}`

  const response = await axios.get<GetFavouriteEpisodesResponse>(url)

  return response.data
}

export const addFavouriteEpisode = async ({ episodeId }: AddFavouriteEpisodeRequest) => {
  const url = `/super-episode-favourites`

  const response = await axios.post<AddFavouriteEpisodeResponse>(url, {
    data: {
      superEpisode: episodeId,
    },
  })

  return response.data
}

export const removeFavouriteEpisode = async ({ favouriteId }: RemoveFavouriteEpisodeRequest) => {
  const url = `/super-episode-favourites/${favouriteId}`

  const response = await axios.delete<RemoveFavouriteEpisodeResponse>(url)

  return response.data
}
