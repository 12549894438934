import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

import { en, de } from "./locales"

export const languages = {
  en,
  de,
}
export const languagesTransformer = {
  en: "en",
  "en-US": "en",
  "en-GB": "en",
  "en-AU": "en",
  "en-CA": "en",
  "en-IN": "en",
  "en-NZ": "en",
  "en-ZA": "en",
  "en-GB-oxendict": "en",
  de: "de-DE",
  "de-DE": "de-DE",
  "de-LI": "de-DE",
  "de-AT": "de-DE",
  "de-CH": "de-DE",
}
export const languagesKeys = Object.keys(languages)

export const languagesMap: { [k: string]: string } = Object.freeze({
  en: "English",
  "de-DE": "Deutsch",
})

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ["navigator", "localStorage", "querystring", "cookie", "sessionStorage", "htmlTag", "path", "subdomain"],
    },
    resources: languages,
    fallbackLng: "en", // use en in case the default user language could not be found
    keySeparator: ".", // important! allow deep key implementation
    debug: process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test", // watch your console for the missing key output
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
