import { useRef, useState } from "react"
import { Box, TextFieldProps } from "@mui/material"
import { Dayjs } from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
// components
import TextField from "../TextField"
import Icon from "shared/components/Icon"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
// styles
import { SDesktopDatePicker, SMobileDatePicker } from "./styles"
// types
import { CustomDesktopDatePickerProps } from "./types"

const DataPicker = ({ onChange = () => {}, ...props }: CustomDesktopDatePickerProps) => {
  const { isSmallDesktop } = useMUIBreakpoints()
  const [value, setValue] = useState<Dayjs | null>(null)
  const rootRef = useRef()

  return (
    <Box ref={rootRef}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {isSmallDesktop ? (
          <SMobileDatePicker
            PopperProps={{
              anchorEl: () => rootRef.current,
            }}
            inputFormat="DD/MM/YYYY"
            value={value}
            renderInput={(params: TextFieldProps) => <TextField {...params} {...props.textFieldProps} />}
            {...props}
            onChange={(value: Dayjs) => {
              if (value && value.toString() !== "Invalid Date") {
                onChange(value.toISOString())
                setValue(value)

                return
              }

              onChange(value)
              setValue(value)
            }}
          />
        ) : (
          <SDesktopDatePicker
            PopperProps={{
              anchorEl: () => rootRef.current,
            }}
            inputFormat="DD/MM/YYYY"
            value={value}
            renderInput={(params: TextFieldProps) => <TextField {...params} {...props.textFieldProps} />}
            components={{
              OpenPickerIcon: () => <Icon name="calendar" />,
            }}
            {...props}
            onChange={(value: Dayjs) => {
              if (value && value.toString() !== "Invalid Date") {
                onChange(value.toISOString())
                setValue(value)

                return
              }

              onChange(value)
              setValue(value)
            }}
          />
        )}
      </LocalizationProvider>
    </Box>
  )
}

export default DataPicker
