// styles
import { customStyled } from "styles/theme"

export const SLanguageButtonWrapper = customStyled("div")(
  ({
    theme: {
      palette: { black },
      spacing,
      breakpoints,
    },
  }) => ({
    width: "100%",

    ".MuiButtonBase-root.Mui-disabled": {
      color: black.lacquer["200"],
      backgroundColor: black.lacquer["700"],
    },

    [breakpoints.down("xs")]: {
      position: "fixed",
      bottom: spacing(5),
      left: spacing(5),
      width: `calc(100% - ${spacing(5)} - ${spacing(5)})`,
    },
  })
)
