import { Radio as MUIRadio, FormControlLabel as MUIFormControlLabel } from "@mui/material"
// styles
import { customStyled } from "styles/theme"
import { shouldForwardProp } from "styles/helpers"
// types
import { CustomRadioProps } from "./types"

export const SFormControlLabel = customStyled(MUIFormControlLabel, {
  shouldForwardProp: (prop) => shouldForwardProp([], prop),
})<CustomRadioProps>(
  ({
    theme: {
      palette,
      spacing,
      typography: { h5, mobileH3 },
      breakpoints,
    },
  }) => ({
    margin: 0,
    padding: `${spacing(5)} 0`,
    width: "100%",

    "& .MuiFormControlLabel-label, & .MuiFormControlLabel-label.Mui-disabled": {
      ...h5,
      color: palette.white.main,
      marginRight: spacing(6),
      width: "100%",

      [breakpoints.down("md")]: {
        ...mobileH3,
      },
    },
  })
)

export const SRadio = customStyled(MUIRadio, {
  shouldForwardProp: (prop) => shouldForwardProp(["labelPlacement"], prop),
})<CustomRadioProps>(({ theme: { palette } }) => ({
  "&, &.Mui-disabled": {
    padding: 0,
    color: palette.black.lacquer["400"],
  },

  "&.Mui-checked ~ span, &.Mui-disabled.Mui-checked ~ span, &.Mui-disabled.Mui-checked": {
    color: palette.green.hyperpop["500"],
  },
}))
