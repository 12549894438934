// styles
import { customStyled } from "styles/theme"
import { shouldForwardProp } from "styles/helpers"

export const SPhotoUploadWrapper = customStyled("div", {
  shouldForwardProp: (prop) => shouldForwardProp(["size"], prop),
})<any>(({ size }) => ({
  position: "relative",
  width: `${size}px`,
  height: `${size}px`,
  minWidth: `${size}px`,
  minHeight: `${size}px`,
}))
