import { Box, Typography } from "@mui/material"
import { Blurhash } from "react-blurhash"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
import { useToggleVisibility } from "hooks/useToggleVisibility"
// components
import CircleProgress from "shared/components/CircleProgress"
import ProgressBar from "shared/components/ProgressBar"
import TagsSet from "../components/TagsSet"
// styles
import { CardWrapper, NavLinkWrapper } from "../styles"
import { BackgroundGradientBox, CardOverflowWrapper } from "./styles"
// types
import { CourseCardProps } from "./types"

const CourseCard = ({
  redirectUrl = "/",
  backgroundImage,
  backgroundImageHash,
  topTags,
  bottomTags,
  circleMaxValue,
  circleCurrentValue,
  backgroundHoverMedia,
  episodesCount,
  episodesWatched,
  width,
  height,
  variant,
  title,
}: CourseCardProps) => {
  const { isSmallDesktop } = useMUIBreakpoints()

  const { isVisible: isBackgroundLoaded, handleShow: showImage } = useToggleVisibility()

  return (
    <NavLinkWrapper to={redirectUrl} data-testid={variant}>
      <CardWrapper
        variant={variant}
        width={width}
        height={height}
        backgroundImage={backgroundImage}
        backgroundHoverMedia={backgroundHoverMedia}
      >
        {!isBackgroundLoaded && backgroundImageHash && (
          <Box sx={{ position: "absolute", padding: 0, left: 0, top: 0, width: "100%", height: "100%" }}>
            <img src={backgroundImage} style={{ display: "none" }} onLoad={() => showImage()} />
            <Blurhash
              hash={backgroundImageHash}
              width="100%"
              height="100%"
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </Box>
        )}
        <CardOverflowWrapper>
          {!!circleMaxValue && !!circleCurrentValue && (
            <CircleProgress
              maxValue={circleMaxValue}
              currentValue={circleCurrentValue}
              size={isSmallDesktop ? "mobileMedium" : "medium"}
              withBackground
              textVariant={isSmallDesktop ? "mobileDescription" : "description"}
            />
          )}
          {topTags && <TagsSet tags={topTags} />}
        </CardOverflowWrapper>
        <CardOverflowWrapper>
          {variant === "specialty" && title && (
            <Typography
              sx={[
                (theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    ...theme.typography.mobileH4,
                  },
                }),
              ]}
              variant="h5"
              color="white.main"
            >
              {title}
            </Typography>
          )}
          {bottomTags && <TagsSet tags={bottomTags} />}
          {typeof episodesCount === "number" && typeof episodesWatched === "number" && (
            <ProgressBar
              maxProgress={episodesCount}
              currentProgress={episodesWatched}
              className="course-card__progress-bar"
            />
          )}
        </CardOverflowWrapper>
        <BackgroundGradientBox />
      </CardWrapper>
    </NavLinkWrapper>
  )
}

export default CourseCard
