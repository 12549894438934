type CreateUploadFormData = { file: File; ref?: string; refId?: number | string; field?: string }

export const createUploadFormData = ({ file, ref, refId, field }: CreateUploadFormData) => {
  const data = new FormData()

  data.append("files", file)

  if (ref && refId && field) {
    data.append("ref", ref)
    data.append("refId", String(refId))
    data.append("field", field)
  }

  return data
}
