export const colors = {
  white: {
    main: "#FFF",
  },
  black: {
    main: "#1E1E1E",
    dark: "#000",
    lacquer: {
      25: "#F8F8F8",
      50: "#F2F2F2",
      100: "#E5E5E5",
      200: "#C9C9C9",
      300: "#A8A8A8",
      400: "#7E7E7E",
      500: "#3E3E3E",
      600: "#373737",
      700: "#303030",
      800: "#272727",
      900: "#1B1B1B",
      950: "#131313",
    },
  },
  green: {
    mid: "#003333",
    midLight: "#004949",
    dark: "#021D1D",
    pastel: "#E9F6F2",
    hyperpop: {
      50: "#F2FDF7",
      100: "#E4FCEF",
      200: "#C6FADE",
      300: "#A2F7CC",
      400: "#73F5B8",
      500: "#16F3A2",
      600: "#13D990",
      700: "#11BC7D",
      800: "#0D9966",
      900: "#096C48",
    },
  },
  orange: {
    hyperpop: {
      50: "#FFF2DF",
      100: "#FFDDB0",
      200: "#FFC77D",
      300: "#FFAF49",
      500: "#FE8D00",
      600: "#FA8100",
      700: "#F47101",
      800: "#EE6002",
      900: "#E44304",
    },
  },
  cyan: {
    main: "#00F0FF",
  },
  red: {
    main: "#FF5F4F",
  },
}

export const gradients = {
  radial: "radial-gradient(circle, rgba(22,243,162,1) 0%, rgba(0,240,255,1) 88%, rgba(0,240,255,1) 100%)",
  linear: {
    greencyan: `linear-gradient(${colors.cyan.main}, ${colors.green.hyperpop["500"]})`,
    cardBackground:
      "linear-gradient(180deg, rgba(17, 17, 17, 8e-05) 0%, rgba(17, 17, 17, 0.096) 30%, rgba(17, 17, 17, 0.2) 50%, rgba(17, 17, 17, 0.4) 70%, rgba(17, 17, 17, 0.6) 90%, rgba(17, 17, 17, 0.704) 96.25%, rgba(17, 17, 17, 0.8) 100%)",
    episodesSlider:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.25) 30.21%, rgba(0, 0, 0, 0.5) 52.6%, rgba(0, 0, 0, 0.75) 76.04%, #000000 96.25%);",
  },
}
