import { useCallback } from "react"
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
// components
import PageTitle from "shared/components/PageTitle"
import Tag from "shared/components/Tag"
import Icon from "shared/components/Icon"
// styles
import { SCmeDashboardWrapper, SCmeDashboardInner } from "./styles"

type CmeDashboardProps = {
  watchedCoursesCount: number
  passedCmeTestCoursesCount: number
  handleOpenWatchedCourses: () => void
  handleOpenPassedCmeTests: () => void
  isLoading: boolean
  userCmePoints?: number
}

const CmeDashboard = ({
  userCmePoints,
  watchedCoursesCount,
  passedCmeTestCoursesCount,
  handleOpenWatchedCourses: onOpenWatchedCourses,
  handleOpenPassedCmeTests: onOpenPassedCmeTests,
  isLoading,
}: CmeDashboardProps) => {
  const { t } = useTranslation()
  const { isMobile } = useMUIBreakpoints()

  const handleOpenWatchedCourses = useCallback(() => {
    if (!isLoading) onOpenWatchedCourses()
  }, [isLoading])

  const handleOpenPassedCmeTests = useCallback(() => {
    if (!isLoading) onOpenPassedCmeTests()
  }, [isLoading])

  return (
    <SCmeDashboardWrapper>
      <PageTitle
        beforeBackgroundWord={t("settings:cme:dashboard:title:beforeBackgroundWord")}
        backgroundWord={t("settings:cme:dashboard:title:backgroundWord")}
        sx={[
          (theme) => ({
            mt: 13,
            mb: 11,
            [theme.breakpoints.down("md")]: {
              mt: 4,
              mb: 10,
            },
          }),
        ]}
      />
      <SCmeDashboardInner sx={{ cursor: "default" }}>
        <Tag variant="secondary" sx={{ backgroundColor: "rgba(22, 243, 162, 0.1)" }}>
          {t("settings:cme:dashboard:earnedPoints.title")}
        </Tag>
        <Box sx={{ alignSelf: "center", mt: 4, mb: 10 }}>
          <Typography
            component="h1"
            variant="h1"
            sx={[
              (theme) => ({
                textAlign: "center",
                fontSize: "92px",
                color: theme.palette.green.hyperpop[500],
                mt: theme.spacing(8),
                mb: theme.spacing(5),
                [theme.breakpoints.down("sm")]: {
                  fontSize: "72px",
                  mt: theme.spacing(5),
                  mb: theme.spacing(3),
                },
              }),
            ]}
          >
            {userCmePoints || 0}
          </Typography>
          <Typography component="h6" variant={isMobile ? "mobileH3" : "h6"}>
            {t("settings:cme:dashboard:earnedPoints.circleDescription")}
          </Typography>
        </Box>
      </SCmeDashboardInner>
      <SCmeDashboardInner onClick={handleOpenWatchedCourses}>
        <Tag variant="secondary" sx={{ backgroundColor: "rgba(22, 243, 162, 0.1)" }}>
          {t("settings:cme:dashboard:courses.title")}
        </Tag>
        <Typography
          component="h2"
          variant="h1"
          sx={[
            (theme) => ({
              color: theme.palette.green.hyperpop[500],
              mt: theme.spacing(8),
              [theme.breakpoints.down("sm")]: {
                fontSize: "40px",
                mb: theme.spacing(5),
              },
            }),
          ]}
        >
          {watchedCoursesCount}
        </Typography>
        <Box sx={{ mb: 3, display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <Typography component="h6" variant={isMobile ? "mobileH3" : "h6"}>
            {t("settings:cme:dashboard:courses.actionText")}
          </Typography>
          {!isMobile && <Icon name="chevronRight" />}
        </Box>
      </SCmeDashboardInner>
      <SCmeDashboardInner onClick={handleOpenPassedCmeTests}>
        <Tag variant="secondary" sx={{ backgroundColor: "rgba(22, 243, 162, 0.1)" }}>
          {t("settings:cme:dashboard:certificates.title")}
        </Tag>
        <Typography
          component="h2"
          variant="h1"
          sx={[
            (theme) => ({
              color: theme.palette.green.hyperpop[500],
              mt: theme.spacing(8),
              [theme.breakpoints.down("sm")]: {
                fontSize: "40px",
                mb: theme.spacing(5),
              },
            }),
          ]}
        >
          {passedCmeTestCoursesCount}
        </Typography>
        <Box sx={{ mb: 3, display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <Typography component="h6" variant={isMobile ? "mobileH3" : "h6"}>
            {t("settings:cme:dashboard:certificates.actionText")}
          </Typography>
          {!isMobile && <Icon name="chevronRight" />}
        </Box>
      </SCmeDashboardInner>
    </SCmeDashboardWrapper>
  )
}

export default CmeDashboard
