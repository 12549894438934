import { createSlice } from "@reduxjs/toolkit"

// Initial state
export const INITIAL_STATE = {}

export const REDUCER_PREFIX = "user"

const { actions, reducer } = createSlice({
  name: REDUCER_PREFIX,
  initialState: INITIAL_STATE,
  reducers: {},
})

export { actions as UserActions, reducer }
