import { Typography } from "@mui/material"
//components
import Icon from "shared/components/Icon"
// styles
import { CardWrapper, NavLinkWrapper } from "../styles"
import { RedirectButton, InnerWrapper } from "./styles"
// types
import { BrandCardProps } from "./types"

const BrandCard = ({ title, backgroundImage, buttonText, redirectUrl, variant }: BrandCardProps) => (
  <CardWrapper
    variant={variant}
    backgroundImage={backgroundImage}
    sx={(theme) => ({
      p: theme.spacing(5),
    })}
  >
    <InnerWrapper>
      {variant === "brandSecondary" && (
        <Icon
          name="logoDark"
          sx={[
            (theme) => ({
              marginBottom: "-20px",
              [theme.breakpoints.down("md")]: {
                "& > svg": { height: "16px" },
              },
            }),
          ]}
        />
      )}
      <div style={{ height: "-webkit-fill-available", display: "flex" }}>
        <Typography
          sx={[
            (theme) => ({
              ...theme.typography.h4,
              lineHeight: "22px",
              alignSelf: "center",
              [theme.breakpoints.down("md")]: {
                ...theme.typography.mobileH3,
                lineHeight: "17px",
              },
            }),
          ]}
          variant="h4"
          color={variant === "brandSecondary" ? "black.main" : "white.main"}
        >
          {title}
        </Typography>
      </div>
      {buttonText && redirectUrl && (
        <NavLinkWrapper to={redirectUrl} sx={{ width: "100%" }}>
          <RedirectButton variant="contained" color="success">
            {buttonText}
          </RedirectButton>
        </NavLinkWrapper>
      )}
    </InnerWrapper>
  </CardWrapper>
)

export default BrandCard
