import axios from "axios"
import qs from "qs"
// constants
import { languagesTransformer } from "i18n"
import { REACT_APP_STRAPI_API_URL } from "constants/endpoints"
// types
import { Language } from "types/i18n"
import { Pagination } from "types/api"
import { ViewPoint, ViewPointAttributes } from "types/episodeProgress"

type GetEpisodeViewpointsRequest = {
  episodesIds: number[]
  locale: Language
}

export type GetEpisodeViewpointsResponse = {
  data: ViewPoint[]
  meta: Pagination
}

export const EPISODE_WATCH_KEYS = Object.freeze({
  GET_VIEWPOINTS: "getViewpoints",
  GET_VIEWPOINT: "getViewpoint",
})

export const getEpisodeViewpoints = async ({ episodesIds, locale = "en" }: GetEpisodeViewpointsRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      filters: {
        episode: {
          id: {
            $in: episodesIds,
          },
        },
      },
      populate: {
        episode: {
          fields: ["id"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}episode-positions?${params}`

  const response = await axios.get<GetEpisodeViewpointsResponse>(url)

  return response.data
}

type GetEpisodeViewpointRequest = {
  episodeId: number
  locale: Language
}

export type GetEpisodeViewpointResponse = {
  data: ViewPoint[]
  meta: Pagination
}

export const getEpisodeViewpoint = async ({ episodeId, locale = "en" }: GetEpisodeViewpointRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      filters: {
        episode: {
          id: {
            $eq: episodeId,
          },
        },
      },
      populate: {
        episode: {
          fields: ["id"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}episode-positions?${params}`

  const response = await axios.get<GetEpisodeViewpointResponse>(url)

  return response.data
}

export type CreateEpisodeViewpointRequest = {
  episodeId: number
  currentPosition: number
  locale?: Language
}

export type CreateEpisodeViewpointResponse = {
  data: ViewPoint<Omit<ViewPointAttributes, "createdAt" | "updatedAt" | "episode">>
  meta: Pagination
}

export const createEpisodeViewpoint = async ({
  episodeId,
  currentPosition,
  locale = "en",
}: CreateEpisodeViewpointRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      fields: ["currentPosition", "maximumPosition", "progress", "isWatched"],
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}episode-positions?${params}`

  const response = await axios.post<CreateEpisodeViewpointResponse>(url, {
    data: {
      episode: episodeId,
      currentPosition,
    },
  })

  return response.data
}

export type UpdateEpisodeViewpointRequest = {
  viewPointId: number
  currentPosition: number
  locale?: Language
}

export type UpdateEpisodeViewpointResponse = {
  data: ViewPoint<Omit<ViewPointAttributes, "createdAt" | "updatedAt" | "episode">>
  meta: Pagination
}

export const updateEpisodeViewpoint = async ({
  viewPointId,
  currentPosition,
  locale = "en",
}: UpdateEpisodeViewpointRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      fields: ["currentPosition", "maximumPosition", "progress", "isWatched"],
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}episode-positions/${viewPointId}?${params}`

  const response = await axios.put<UpdateEpisodeViewpointResponse>(url, {
    data: {
      currentPosition,
    },
  })

  return response.data
}
