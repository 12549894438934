import { createRef, useState, useCallback, ChangeEvent } from "react"
import { Avatar, Typography, SxProps } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
// components
import Icon from "shared/components/Icon"
// constants
import { REACT_APP_STRAPI_URL } from "constants/endpoints"
// styles
import { CustomTheme } from "styles/theme"
import { SPhotoUploadWrapper } from "./styles"

type Props = {
  name: string
  bigPhotoFileError: string
  defaultPhotoUrl?: string
  size?: "medium" | "large" | "mobileMedium"
  sx?: SxProps<CustomTheme>
}

const PhotoUpload = ({ name, bigPhotoFileError, defaultPhotoUrl, size, sx }: Props) => {
  const [image, setImage] = useState<undefined | string>(defaultPhotoUrl)

  const inputFileRef = createRef<any>()
  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext()

  let photoSize: number

  switch (size) {
    case "medium":
      photoSize = 108
      break
    case "large":
      photoSize = 124
      break
    case "mobileMedium":
      photoSize = 88
      break
    default:
      photoSize = 108
  }

  const cleanupImage = useCallback(() => {
    URL.revokeObjectURL(image || "")
    if (inputFileRef.current) inputFileRef.current.value = null
  }, [image])

  const handleSelectImage = useCallback(
    async ({ event, onChange }: { event: ChangeEvent<HTMLInputElement>; onChange: (newImage: File) => void }) => {
      // limit 8.1 Megabytes
      if (bigPhotoFileError && (event.target?.files?.[0]?.size || 0) > 8493465) {
        setError(name, { type: "custom", message: bigPhotoFileError })
        return
      }

      const newImage = event.target?.files?.[0]

      if (newImage) {
        if (image) {
          clearErrors(name)
          cleanupImage()
        }
        onChange(newImage)
        setImage(URL.createObjectURL(newImage))
      }
    },
    [cleanupImage]
  )

  const handleDeleteImage = useCallback(
    ({ onChange }: { onChange: (newImage: File | null) => void }) => {
      if (image) {
        clearErrors(name)
        cleanupImage()
        onChange(null)
        setImage(undefined)
      }
    },
    [cleanupImage, name]
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <SPhotoUploadWrapper size={photoSize} sx={sx}>
          <Avatar
            src={image ? `${image?.split(":")[0] === "blob" ? "" : REACT_APP_STRAPI_URL}${image}` : ""}
            sx={[
              ({ palette }) => ({
                width: "100%",
                height: "100%",
                backgroundColor: palette.black.lacquer[800],
              }),
            ]}
            imgProps={{
              style: {
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "cover",
              },
            }}
          >
            <Icon
              name="profile"
              sx={[
                ({ palette }) => ({
                  "& svg": { height: `${photoSize / 2}px`, width: `${photoSize / 2}px` },
                  "& path": {
                    fill: palette.black.lacquer[400],
                  },
                }),
              ]}
            />
          </Avatar>
          <input
            name={field.name}
            ref={inputFileRef}
            accept="image/png,image/jpeg"
            hidden
            type="file"
            onChange={(e) => {
              handleSelectImage({ event: e, onChange: field.onChange })
            }}
          />
          {image && (
            <Icon
              name="delete"
              withBackground
              onClick={() => handleDeleteImage({ onChange: field.onChange })}
              sx={[
                ({ palette }) => ({
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "31px",
                  height: "31px",
                  backgroundColor: palette.black.lacquer[500],
                  cursor: "pointer",
                }),
              ]}
            />
          )}
          <Icon
            name="addImage"
            withBackground
            onClick={() => inputFileRef.current?.click()}
            sx={[
              ({ palette, spacing }) => ({
                position: "absolute",
                bottom: 0,
                right: `-${spacing(5)}`,
                width: "48px",
                height: "48px",
                backgroundColor: palette.black.lacquer[500],
                cursor: "pointer",
              }),
            ]}
          />
          {!!errors[name] && (
            <Typography
              variant="h5"
              mt={5}
              sx={[
                ({ palette, typography }) => ({
                  ...typography.mobileH3,
                  color: palette.warning.dark,
                  whiteSpace: "nowrap",
                  overflow: "visible",
                }),
              ]}
            >
              {errors[name]?.message as string}
            </Typography>
          )}
        </SPhotoUploadWrapper>
      )}
    />
  )
}

export default PhotoUpload
