import { Typography, Box } from "@mui/material"
import { useTranslation } from "react-i18next"
// assets
import betaBackgroundImage from "assets/images/backgrounds/beta.svg"
// styles
import { SMembership } from "./styles"

const Membership = () => {
  const { t } = useTranslation()

  return (
    <SMembership>
      <Box
        component="img"
        sx={{
          height: "100%",
          width: "100%",
          maxWidth: 822,
        }}
        alt="Beta Background"
        src={betaBackgroundImage}
      />
      <Typography component="p" mt={13} sx={{ fontSize: "30px", maxWidth: "710px", textAlign: "center" }}>
        {t("settings:membership:betaDescription:beforeHighlighted")}{" "}
        <Box component="span" color="green.hyperpop.500">
          {t("settings:membership:betaDescription:highlighted")}
        </Box>{" "}
        {t("settings:membership:betaDescription:afterHighlighted")}
      </Typography>
    </SMembership>
  )
}

export default Membership
