import { Alert, Box, Snackbar, Typography } from "@mui/material"
import { FormProvider, useForm, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useMemo } from "react"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
import useToggleSnackbar from "hooks/useToggleSnackbar"
import { useOnboarding } from "hooks/useOnboarding"
import { useSpecialties } from "pages/hooks/useSpecialties"
import { useUpload } from "hooks/useUpload"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// components
import Button from "shared/components/Button"
import { Autocomplete, DataPicker, TextField, PhotoUpload } from "shared/components/input"
// utils
import { createUploadFormData } from "utils/upload"
// constants
import { GENDER_OPTIONS, JOB_POSITIONS, JOB_POSITIONS_VALUES } from "constants/auth"
// styles
import { SProfileInfoWrapper, STitle } from "./styles"
import { SInnerWrapper } from "../styles"
// types
import { Option } from "shared/components/input/Autocomplete/types"
import { Language } from "types/i18n"

interface FormData {
  title: string
  userFirstName: string
  userLastName: string
  userGender: Option
  userDateOfBirth: string
  userJobPosition: Option
  specialty: Option<number | string>
  userJobTitle: string
  userEFN: string
  userPartnercode: string
  userPicture: File | null
}
const validator = (t: TFunction) =>
  yup.object().shape({
    userFirstName: yup.string().required(t("settings:profile:inputFields:userFirstName:errors:required")),
    userLastName: yup.string().required(t("settings:profile:inputFields:userLastName:errors:required")),
    userGender: yup.object().required(t("settings:profile:inputFields:userGender:errors:required")).nullable(),
    userDateOfBirth: yup
      .string()
      .required(t("settings:profile:inputFields:userDateOfBirth:errors:required"))
      .test({
        name: "isValidFormat",
        test(value: string | undefined, ctx) {
          if (value === "Invalid Date") {
            return ctx.createError({ message: t("settings:profile:inputFields:userDateOfBirth:errors:format") })
          }

          return true
        },
      })
      .nullable(),
    userJobPosition: yup
      .object()
      .required(t("settings:profile:inputFields:userJobPosition:errors:required"))
      .nullable(),
    specialty: yup.object().required(t("settings:profile:inputFields:specialty:errors:required")).nullable(),
    // userJobTitle: yup
    //   .string()
    //   .when("userJobPosition.value", (_userJobTitle, schema) =>
    //     _userJobTitle === JOB_POSITIONS_VALUES.Student
    //       ? schema
    //       : schema.required(t("settings:profile:inputFields:userJobTitle:errors:required"))
    //   ),
    userEFN: yup
      .string()
      .test("digits", t("settings:profile:inputFields:userEFN:errors:digits"), (val) => !isNaN(Number(val)))
      .test("len", t("settings:profile:inputFields:userEFN:errors:digitsAmount"), (val) => {
        if (val === undefined) {
          return true
        }

        return val.length === 0 || val.length === 15
      }),
  })

const Profile = () => {
  const { t, i18n } = useTranslation()
  const { isMobile } = useMUIBreakpoints()
  const {
    user: {
      name,
      specialty,
      pictureUrl,
      pictureId,
      userProfileId,
      firstName,
      lastName,
      gender,
      title,
      userDateOfBirth,
      userJobPosition,
      userJobTitle,
      userEFN,
      // TODO: hidden according to MN-361
      // userPartnercode,
    },
  } = useAuthenticationContext()

  const { updateUserData, isLoading, isError, error } = useOnboarding()
  const { upload, deleteUpload, isLoading: isUploadImageLoading } = useUpload()

  const { open, handleClose } = useToggleSnackbar(isError)

  const { specialties = [{ id: -1, name: "" }], isLoading: isSpecialtiesLoading } = useSpecialties(
    i18n.language.slice(0, 2) as Language
  )
  const _specialties = useMemo(() => specialties.map((s) => ({ value: s.id, label: s.name })), [specialties])
  const genderOptions = useMemo(
    () => GENDER_OPTIONS.get(i18n.language.slice(0, 2) as Language)!,
    [GENDER_OPTIONS, i18n.language]
  )
  const jobPositions = useMemo(
    () => JOB_POSITIONS.get(i18n.language.slice(0, 2) as Language)!,
    [JOB_POSITIONS, i18n.language]
  )

  const methods = useForm<FormData>({
    resolver: yupResolver(validator(t)),
    defaultValues: {
      title: title || "",
      userFirstName: firstName || "",
      userLastName: lastName || "",
      userGender: genderOptions.find((g) => g.value === gender) || genderOptions[0],
      userDateOfBirth: new Date(userDateOfBirth).toISOString() || "",
      userJobPosition: jobPositions.find((j) => j.value === userJobPosition),
      specialty: { value: 0, label: "" },
      userJobTitle: userJobTitle || "",
      ...(userEFN ? { userEFN } : { userEFN: "" }),
      // TODO: hidden according to MN-361
      // userPartnercode: userPartnercode || "",
    },
    reValidateMode: "onChange",
  })

  const handleFormSubmit = ({
    title,
    userFirstName,
    userLastName,
    userGender,
    userDateOfBirth,
    userJobPosition,
    specialty,
    userJobTitle,
    userEFN,
    // TODO: hidden according to MN-361
    // userPartnercode,
    userPicture,
  }: FormData) => {
    updateUserData({
      userProfileId,
      userFirstName,
      userLastName,
      title,
      userGender: userGender.value,
      userDateOfBirth,
      userJobPosition: userJobPosition.value,
      specialty: specialty.value,
      ...(userJobTitle ? { userJobTitle } : { userJobTitle: "" }),
      ...(userEFN ? { userEFN } : { userEFN: null }),
      // TODO: hidden according to MN-361
      // userPartnercode,
    })

    if (userPicture) {
      const data = createUploadFormData({
        file: userPicture,
        ref: "api::user-profile.user-profile",
        refId: String(userProfileId),
        field: "userPicture",
      })

      upload(data)
    }

    if (userPicture === null && pictureId) {
      deleteUpload({ uploadId: pictureId })
    }

    methods.reset({}, { keepValues: true })
  }

  useEffect(() => {
    methods.setValue("specialty", _specialties.find((s) => s.label === specialty)!)
  }, [_specialties, specialty])

  const watchedUserJobPosition = methods.watch("userJobPosition")

  useEffect(() => {
    if (watchedUserJobPosition?.value === JOB_POSITIONS_VALUES.Student) {
      methods.unregister("userJobTitle", {
        keepValue: true,
        keepError: false,
      })
    }
  }, [watchedUserJobPosition, pictureId])

  return (
    <SInnerWrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <SProfileInfoWrapper>
            <PhotoUpload
              size={isMobile ? "mobileMedium" : "medium"}
              defaultPhotoUrl={pictureUrl}
              name="userPicture"
              bigPhotoFileError={t("settings:profile:bigPhotoFileError")}
              sx={{ mr: 11 }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant={isMobile ? "mobileH3" : "h5"} component="h5" mb={isMobile ? 2 : 3}>
                {name}
              </Typography>
              <Typography variant={isMobile ? "mobileH4" : "h5"} color="black.lacquer.400" component="p">
                {specialty}
              </Typography>
            </Box>
          </SProfileInfoWrapper>
          <STitle>{t("settings:profile:personalTitle")}</STitle>
          <Controller
            name="title"
            control={methods.control}
            render={({
              field,
              formState: {
                errors: { title },
              },
            }) => (
              <TextField
                type="text"
                inputProps={field}
                error={!!title?.message}
                helperText={title?.message}
                sx={{ mb: 4 }}
                placeholder={t("settings:profile:inputFields:title:placeholder")}
              />
            )}
          />
          <Controller
            name="userFirstName"
            control={methods.control}
            render={({
              field,
              formState: {
                errors: { userFirstName },
              },
            }) => (
              <TextField
                type="text"
                inputProps={field}
                error={!!userFirstName?.message}
                helperText={userFirstName?.message}
                sx={{ mb: 4 }}
                placeholder={t("settings:profile:inputFields:userFirstName:placeholder")}
              />
            )}
          />
          <Controller
            name="userLastName"
            control={methods.control}
            render={({
              field,
              formState: {
                errors: { userLastName },
              },
            }) => (
              <TextField
                type="text"
                inputProps={field}
                error={!!userLastName?.message}
                helperText={userLastName?.message}
                sx={{ mb: 4 }}
                placeholder={t("settings:profile:inputFields:userLastName:placeholder")}
              />
            )}
          />
          <Controller
            name="userGender"
            control={methods.control}
            render={({
              field: { onChange, ref, ...field },
              formState: {
                errors: { userGender },
              },
            }) => (
              <Autocomplete
                {...field}
                onChange={(_, data) => onChange(data)}
                sx={{ mb: 4 }}
                textFieldProps={{
                  ...field,
                  error: !!userGender?.message,
                  helperText: userGender?.message,
                  label: t("settings:profile:inputFields:userGender:placeholder"),
                  inputRef: ref,
                }}
                options={genderOptions}
              />
            )}
          />
          <Box sx={{ position: "relative" }}>
            <Controller
              name="userDateOfBirth"
              control={methods.control}
              render={({
                field: { onChange, ref, ...field },
                formState: {
                  errors: { userDateOfBirth },
                },
              }) => (
                <DataPicker
                  {...field}
                  onChange={onChange}
                  textFieldProps={{
                    ...field,
                    sx: { marginBottom: 4 },
                    error: !!userDateOfBirth?.message,
                    helperText: userDateOfBirth?.message,
                    label: t("settings:profile:inputFields:userDateOfBirth:placeholder"),
                    inputRef: ref,
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </Box>
          <STitle>{t("settings:profile:professionalTitle")}</STitle>
          <Controller
            name="userJobPosition"
            control={methods.control}
            render={({
              field: { onChange, ref, ...field },
              formState: {
                errors: { userJobPosition },
              },
            }) => (
              <Autocomplete
                {...field}
                onChange={(_, data) => onChange(data)}
                sx={{ mb: 4 }}
                textFieldProps={{
                  ...field,
                  error: !!userJobPosition?.message,
                  helperText: userJobPosition?.message,
                  label: t("settings:profile:inputFields:userJobPosition:placeholder"),
                  inputRef: ref,
                }}
                options={jobPositions}
              />
            )}
          />
          <Controller
            name="specialty"
            control={methods.control}
            render={({
              field: { onChange, ref, ...field },
              formState: {
                errors: { specialty },
              },
            }) => (
              <Autocomplete
                {...field}
                loading={isSpecialtiesLoading}
                loadingText={t("shared:loading")}
                onChange={(_, data) => onChange(data)}
                sx={{ mb: 4 }}
                textFieldProps={{
                  ...field,
                  error: !!specialty?.message,
                  helperText: specialty?.message,
                  label: t("settings:profile:inputFields:specialty:placeholder"),
                  inputRef: ref,
                }}
                options={_specialties.length > 1 ? _specialties : []}
              />
            )}
          />
          {watchedUserJobPosition?.value !== JOB_POSITIONS_VALUES.Student && (
            <Controller
              name="userJobTitle"
              control={methods.control}
              render={({
                field,
                formState: {
                  errors: { userJobTitle },
                },
              }) => (
                <TextField
                  type="text"
                  inputProps={field}
                  error={!!userJobTitle?.message}
                  helperText={userJobTitle?.message}
                  sx={{ mb: 4 }}
                  placeholder={t("settings:profile:inputFields:userJobTitle:placeholder")}
                />
              )}
            />
          )}
          <Controller
            name="userEFN"
            control={methods.control}
            render={({
              field,
              formState: {
                errors: { userEFN },
              },
            }) => (
              <TextField
                type="tel"
                inputProps={field}
                error={!!userEFN?.message}
                helperText={userEFN?.message}
                sx={{ mb: 4 }}
                placeholder={t("settings:profile:inputFields:userEFN:placeholder")}
              />
            )}
          />
          {/*
           TODO: hidden according to MN-361
          <Controller
            name="userPartnercode"
            control={methods.control}
            render={({
              field,
              formState: {
                errors: { userPartnercode },
              },
            }) => (
              <TextField
                type="text"
                inputProps={field}
                error={!!userPartnercode?.message}
                helperText={userPartnercode?.message}
                sx={{ mb: 4 }}
                placeholder={t("settings:profile:inputFields:userPartnercode:placeholder")}
              />
            )}
          /> */}

          {methods.formState.isDirty && (
            <Button
              sx={[
                ({ breakpoints }) => ({
                  zIndex: 1,
                  position: "fixed",
                  left: "calc(50% + 360px)",
                  transform: "translateX(calc(-50% - 180px))",
                  bottom: "10px",
                  width: "437px",
                  [breakpoints.down("md")]: {
                    left: "50%",
                    maxWidth: "calc(100% - 32px)",
                    transform: "translateX(-50%)",
                  },
                  [breakpoints.down("xs")]: {
                    width: "100%",
                  },
                }),
              ]}
              type="submit"
              disabled={!!Object.keys(methods.formState.errors).length || isLoading || isUploadImageLoading}
              color="secondary"
              variant="contained"
            >
              {t("settings:profile:submitButtonText")}
            </Button>
          )}
        </form>
      </FormProvider>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={error}
        onClose={handleClose}
      >
        <Alert severity="error" sx={{ width: "100%" }} onClose={handleClose} closeText="Close">
          {error}
        </Alert>
      </Snackbar>
    </SInnerWrapper>
  )
}

export default Profile
