import { Button as MUIButton } from "@mui/material"
// styles
import { customStyled } from "styles/theme"

const Button = customStyled(MUIButton)(
  ({
    theme: {
      palette,
      spacing,
      shape: { borderRadius },
      typography: { h4 },
    },
    startIcon,
    endIcon,
  }) => ({
    "&.MuiButton-root": {
      ...h4,
      textTransform: "initial",
      padding: `${spacing(4)} ${parseFloat(spacing(4) as any) + 2}px`,
      borderRadius: borderRadius(4),
      ...(startIcon && {
        paddingLeft: spacing(6),
      }),
      ...(endIcon && {
        paddingRight: spacing(6),
      }),
      "&.Mui-disabled": {
        backgroundColor: palette.black.lacquer["600"],
        color: palette.black.lacquer["200"],
        "& path, & rect": {
          stroke: palette.black.lacquer["200"],
        },
        "&:hover": {
          backgroundColor: palette.black.lacquer["600"],
        },
      },
    },
    "&.MuiButton-contained": {
      backgroundColor: palette.black.lacquer["800"],
      color: palette.black.lacquer["200"],
      "& path, & rect": {
        stroke: palette.black.lacquer["200"],
      },
      "&:hover": {
        backgroundColor: palette.black.lacquer["700"],
      },
    },
    "&.MuiButton-containedSecondary": {
      backgroundColor: palette.secondary.main,
      color: palette.green.dark,
      "& path, & rect": {
        stroke: palette.green.dark,
      },
      "&:hover": {
        backgroundColor: palette.green.hyperpop["400"],
      },
    },
    "&.MuiButton-containedSuccess": {
      backgroundColor: palette.green.mid,
      color: palette.green.hyperpop["200"],
      "& path, & rect": {
        stroke: palette.green.hyperpop["200"],
      },
      "&:hover": {
        backgroundColor: palette.green.midLight,
      },
    },
  })
)

export default Button
