import { TFunction } from "i18next"
import { TagsSetTag } from "shared/components/cards/components/TagsSet/types"

export const newTag = (t: TFunction): TagsSetTag => ({ title: t("shared:newCourse"), variant: "filled" })

export const COURSE_TYPE_TRANSFORMER = Object.freeze({
  basicCourse: "course",
  expertCourse: "course",
  guidelineCourse: "course",
  shortUnit: "singleShortVideo",
  journal: "singleShortVideo",
})
