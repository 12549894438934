import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// api
import { TEST_KEYS, getCmeCertificate, GetCmeCertificateResponse } from "api"
// types
import { Language } from "types/i18n"

type UseDownloadCertificate = {
  superCourseId: number
  autoFetchEnabled?: boolean
}

const useDownloadCertificate = ({
  superCourseId,
  autoFetchEnabled = false,
}: UseDownloadCertificate): {
  handleDownloadCmeCertificate: () => void
  cmeCertificateLoading: boolean
} => {
  const {
    user: { id: userId, name: userFullName },
  } = useAuthenticationContext()
  const { i18n } = useTranslation()

  const [cmeCertificateLoading, setCmeCertificateLoading] = useState(false)

  const { refetch: fetchCmeCertificate, isFetching: generateCmeCertificateFetching } =
    useQuery<GetCmeCertificateResponse>({
      queryKey: [TEST_KEYS.CME_CERTIFICATE, superCourseId, i18n.language],
      queryFn: () => getCmeCertificate({ userId, superCourseId, locale: i18n.language as Language }),
      onSuccess: ({ base64Pdf }) => downloadCertificate(base64Pdf),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!superCourseId && autoFetchEnabled,
    })

  useEffect(() => {
    if (generateCmeCertificateFetching) setCmeCertificateLoading(true)
  }, [generateCmeCertificateFetching])

  const downloadCertificate = (base64Pdf: string) => {
    const a = document.createElement("a")
    document.body.appendChild(a)
    a.href = base64Pdf
    a.download = String(`Medudy Certificate, ${userFullName}.pdf`)
    a.click()
    a.remove()
    setCmeCertificateLoading(false)
  }

  return {
    handleDownloadCmeCertificate: fetchCmeCertificate,
    cmeCertificateLoading,
  }
}

export default useDownloadCertificate
