import { useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
// api
import { COMMON_INFORMATION, getDataProtection, GetDataProtectionResponse } from "api"
// types
import { Language } from "types/i18n"

export const useDataProtection = (): {
  dataProtection: string
  isLoading: boolean
} => {
  const { i18n } = useTranslation()

  const { data: fetchedDataProtection, isLoading } = useQuery<GetDataProtectionResponse>({
    queryKey: [COMMON_INFORMATION.GET_DATA_PROTECTION, i18n.language],
    queryFn: () => getDataProtection({ locale: i18n.language as Language }),
  })

  const dataProtection = useMemo(
    () => fetchedDataProtection?.data?.attributes?.dataProtection || "",
    [fetchedDataProtection]
  )

  return {
    dataProtection,
    isLoading,
  }
}
