import { createReduxHistoryContext } from "redux-first-history"
// utils
import history from "utils/history"

// here are both middleware and reducer for router
export const {
  createReduxHistory,
  routerMiddleware: router,
  routerReducer,
} = createReduxHistoryContext({
  history,
  //other options if needed
})
