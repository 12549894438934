import axios from "axios"

type UploadFile = {
  alternativeText: string
  caption: string
  createdAt: string
  ext: string
  formats: Record<string, any>
  hash: string
  height: number
  id: number
  mime: string
  name: string
  placeholder: string
  previewUrl: unknown
  provider: string
  provider_metadata: unknown
  size: number
  updatedAt: string
  url: string
  width: number
}

export type UploadRequest = FormData

export type UploadResponse = {
  0: UploadFile
}

export type DeleteUploadRequest = { uploadId: number }

export type DeleteUploadResponse = UploadFile

export const UPLOAD_KEYS = {
  UPLOAD: "upload",
  DELETE_UPLOAD: "upload",
}

export const upload = async (data: UploadRequest) => {
  const url = `/upload`

  const response = await axios.post<UploadResponse>(url, data)

  return response.data
}

export const deleteUpload = async ({ uploadId }: DeleteUploadRequest) => {
  const url = `/upload/files/${uploadId}`

  const response = await axios.delete<DeleteUploadResponse>(url)

  return response.data
}
