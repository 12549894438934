import { Typography, useTheme } from "@mui/material"
//components
import Icon from "shared/components/Icon"
// styles
import { CardWrapper, NavLinkWrapper } from "../styles"
import { InnerWrapper } from "./styles"
// types
import { ViewAllCardProps } from "./types"

const ViewAllCard = ({ title, redirectUrl = "/", cardSize = "course" }: ViewAllCardProps) => {
  const theme = useTheme()

  return (
    <NavLinkWrapper to={redirectUrl}>
      <CardWrapper
        variant={cardSize}
        sx={[(theme) => ({ backgroundColor: theme.palette.green.dark, p: theme.spacing(5) })]}
      >
        <InnerWrapper>
          <div style={{ height: "-webkit-fill-available", display: "flex" }}>
            <Typography
              sx={[
                (theme) => ({
                  ...theme.typography.h4,
                  lineHeight: "22px",
                  alignSelf: "center",
                  mt: theme.spacing(8),
                  [theme.breakpoints.down("sm")]: {
                    ...theme.typography.mobileH3,
                  },
                }),
              ]}
              variant="h4"
              color="white.main"
            >
              {title}
            </Typography>
          </div>
          {redirectUrl && (
            <div>
              <Icon
                name="chevronRightLong"
                fillColor={theme.palette.green.mid}
                withBackground
                backgroundVariant="secondary"
                sx={[
                  (theme) => ({
                    width: "48px",
                    height: "48px",
                    [theme.breakpoints.down("sm")]: {
                      width: "40px",
                      height: "40px",
                    },
                  }),
                ]}
              />
            </div>
          )}
        </InnerWrapper>
      </CardWrapper>
    </NavLinkWrapper>
  )
}

export default ViewAllCard
