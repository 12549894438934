// styles
import { shouldForwardProp } from "styles/helpers"
import { customStyled } from "styles/theme"
// types
import { ProgressBarProps, ProgressBarStyleProps } from "./types"

const ProgressBar = customStyled("div", {
  shouldForwardProp: (prop) => shouldForwardProp<ProgressBarStyleProps>(["maxProgress", "currentProgress"], prop),
})<ProgressBarProps>(
  ({
    theme: {
      palette,
      shape: { borderRadius },
      breakpoints,
      spacing,
    },
    maxProgress,
    currentProgress,
    variant,
    size,
  }) => ({
    position: "relative",
    width: "100%",
    height: "4px",
    borderRadius: borderRadius(4),
    background: "rgba(255, 255, 255, 0.32)",
    backdropFilter: "blur(2px)",
    marginTop: spacing(4),
    overflow: "hidden",
    ...(size === "small" && {
      height: "2px",
    }),
    ...(variant === "dark" && {
      background: "rgba(0, 0, 0, 0.48)",
    }),
    [breakpoints.down("md")]: {
      height: "3px",
      marginTop: spacing(3),
    },

    "&:after": {
      position: "absolute",
      left: 0,
      top: 0,
      content: "''",
      display: "block",
      backgroundColor: palette.secondary.main,
      width: `${((currentProgress || 0) * 100) / (maxProgress || 0)}%`,
      height: "100%",
      borderRadius: borderRadius(4),
    },
  })
)

export default ProgressBar
