import { useTranslation } from "react-i18next"
// api
import { GetSuperCourseProgressesByStatusResponse } from "api"
// constants
import { languagesTransformer } from "i18n"
// types
import { Language } from "types/i18n"
import { Cover } from "types/courses"

export type NormalizedCourses = {
  [key: string]: {
    cmeTestPassed: boolean
    cmeTestPassedDate: string
    courseWatchedDate: string
    status: string
    cmePointsText: string
    courseId: number
    courseName: string
    specialtiesStr: string
    superCourseId: number
    cover?: Cover
  }[]
}

export const getNormalizedCourses = (
  fetchedCourse: GetSuperCourseProgressesByStatusResponse | undefined
): NormalizedCourses => {
  const { i18n } = useTranslation()
  const transformedLanguage = languagesTransformer[i18n.language.slice(0, 2) as Language]

  const normalizedCoursesByYears: NormalizedCourses = {}
  fetchedCourse?.data.forEach(({ attributes }) => {
    const {
      courseWatchedDate,
      cmeTestPassed,
      cmeTestPassedDate,
      status,
      superCourse: {
        data: {
          id: superCourseId,
          attributes: { courses },
        },
      },
    } = attributes

    const currentCourse = courses.data.find(({ attributes: { locale } }) => locale === transformedLanguage)
    const currentCourseAttributes = currentCourse?.attributes

    const courseWatchedYear = (courseWatchedDate || "")?.split("-")[0]

    const specialtiesStr =
      currentCourseAttributes?.specialties?.data?.reduce(
        (acc, { attributes: { name } }) => (acc ? `${acc}, ${name}` : name),
        ""
      ) || ""

    const normalizedCourse = {
      cmeTestPassed,
      cmeTestPassedDate,
      courseWatchedDate,
      status,
      cmePointsText: currentCourseAttributes?.cmePointsText || "",
      courseId: currentCourse?.id || 0,
      courseName: currentCourseAttributes?.courseName || "",
      cover: currentCourseAttributes?.cover,
      specialtiesStr,
      superCourseId,
    }

    normalizedCoursesByYears[courseWatchedYear] = [
      ...(normalizedCoursesByYears[courseWatchedYear] || []),
      normalizedCourse,
    ]
  })

  return normalizedCoursesByYears
}
