// styles
import { SFormControlLabel, SRadio } from "./styles"
// types
import { CustomRadioProps } from "./types"

const RadioButton = (props: CustomRadioProps) => {
  const { label, value, labelPlacement = "start" } = props
  return (
    <SFormControlLabel
      value={value}
      control={<SRadio color="secondary" {...props} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

export default RadioButton
