import { useCallback, useEffect, useRef, useState } from "react"
import { throttle } from "utils/lodash"

const STARTED_SCROLL = 100

export const useScrolldownToggle = (disable: boolean) => {
  const scrollRef = useRef<null | HTMLDivElement>(null)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [inner, setIn] = useState(false)
  const [outer, setOut] = useState(false)

  const toggleHeader = useCallback(
    throttle(() => {
      const scrollTop = scrollRef?.current?.scrollTop || 0

      if (scrollTop > lastScrollTop && scrollTop > STARTED_SCROLL) {
        setOut(true)
        setIn(false)
      } else if (scrollTop < lastScrollTop && scrollTop > STARTED_SCROLL) {
        setIn(true)
        setOut(false)
      } else if (scrollTop <= STARTED_SCROLL) {
        setIn(false)
        setOut(false)
      }

      setLastScrollTop(scrollTop)
    }, 150),
    [lastScrollTop, scrollRef]
  )

  const handleScroll = useCallback(toggleHeader, [toggleHeader])

  useEffect(() => {
    if (disable) {
      scrollRef?.current?.removeEventListener("scroll", handleScroll)
      return
    }

    scrollRef?.current?.addEventListener("scroll", handleScroll)

    return () => {
      scrollRef?.current?.removeEventListener("scroll", handleScroll)
    }
  }, [scrollRef, handleScroll, disable])

  return {
    inner,
    outer,
    scrollRef,
  }
}
