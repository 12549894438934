// Library
import { HTMLAttributes } from "react"
// React-Query
import { QueryClientProvider } from "@tanstack/react-query"
import { queryClient } from "services/queryClient"
// Redux
import { Provider } from "react-redux"
import { store } from "../store"
// Language
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n"
// Styles
import { ThemeProvider } from "@mui/system"
import { theme } from "styles/theme"

type Children = HTMLAttributes<HTMLDivElement>

const ProviderWrapper = ({ children }: Children) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default ProviderWrapper
