import { Box, CircularProgress } from "@mui/material"
import { GlobalLoadingProps } from "./types"

const GlobalLoading = ({ wrapped }: GlobalLoadingProps) => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000",
        ...(wrapped && {
          width: "auto",
          height: "auto",
          padding: 8,
        }),
      }}
    >
      <CircularProgress
        sx={{
          display: "block",
          width: "120px",
          height: "120px",
          ...(wrapped && {
            width: "40px",
            height: "40px",
            padding: 8,
          }),
        }}
        variant="indeterminate"
        color="secondary"
        size="120px"
      />
    </Box>
  )
}

export default GlobalLoading
