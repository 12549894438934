import { createStyled } from "@mui/system"
import { createTheme } from "@mui/material"
// constants
import { colors, gradients } from "constants/colors"
import { Z_INDEXES, CARD_SIZES_MAPPING } from "constants/theme"
import { breakpoints } from "constants/breakpoints"
import { typography } from "constants/typography"

export const themeOverrides = {
  palette: {
    primary: {
      main: colors.white.main,
      contrastText: colors.black.dark,
    },
    secondary: {
      main: colors.green.hyperpop["500"],
      light: colors.green.hyperpop["400"],
      dark: colors.green.hyperpop["600"],
      contrastText: colors.black.dark,
    },
    warning: {
      main: colors.orange.hyperpop["300"],
    },
    error: {
      main: colors.red.main,
      contrastText: colors.black.dark,
    },
    info: {
      main: colors.black.lacquer["400"],
      light: colors.black.lacquer["300"],
      dark: colors.black.lacquer["500"],
      contrastText: colors.white.main,
    },
    white: {
      main: colors.white.main,
    },
    black: {
      main: colors.black.main,
      dark: colors.black.dark,
      lacquer: {
        25: colors.black.lacquer["25"],
        50: colors.black.lacquer["50"],
        100: colors.black.lacquer["100"],
        200: colors.black.lacquer["200"],
        300: colors.black.lacquer["300"],
        400: colors.black.lacquer["400"],
        500: colors.black.lacquer["500"],
        600: colors.black.lacquer["600"],
        700: colors.black.lacquer["700"],
        800: colors.black.lacquer["800"],
        900: colors.black.lacquer["900"],
        950: colors.black.lacquer["950"],
      },
    },
    green: {
      mid: colors.green.mid,
      midLight: colors.green.midLight,
      dark: colors.green.dark,
      pastel: colors.green.pastel,
      hyperpop: {
        50: colors.green.hyperpop["50"],
        100: colors.green.hyperpop["100"],
        200: colors.green.hyperpop["200"],
        300: colors.green.hyperpop["300"],
        400: colors.green.hyperpop["400"],
        500: colors.green.hyperpop["500"],
        600: colors.green.hyperpop["600"],
        700: colors.green.hyperpop["700"],
        800: colors.green.hyperpop["800"],
        900: colors.green.hyperpop["900"],
      },
    },
    red: {
      main: colors.red.main,
    },
    cyan: {
      main: colors.cyan.main,
    },
    text: {
      primary: colors.white.main,
      secondary: colors.green.hyperpop["500"],
      disabled: "rgba(0,0,0, 0.3)",
    },
    gradients: gradients,
    background: {
      default: colors.black.dark,
    },
  },

  typography: typography,
  spacing: (factor: number) => [0, 2, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 48, 80, 120, 160][factor],
  shape: {
    borderRadius: (factor: number) => [0, 2, 4, 6, 8, 10, 12, 14, 16][factor] + "px",
  },
  transitions: {
    duration: {
      standard: 300,
    },
    easing: {
      easeInOut: "cubic-bezier(.42, 0, .58, 1)",
    },
  },
  zIndex: Z_INDEXES,
  cardSizes: CARD_SIZES_MAPPING,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*, *::after, *::before": {
          boxSizing: "border-box",
        },
        "*::-webkit-scrollbar": {
          zIndex: Z_INDEXES.overAll,
          width: "6px",
          "@media(max-width: 767.9px)": {
            display: "none",
          },
        },
        "*::-webkit-scrollbar-track": {
          background: colors.black.lacquer["700"],
        },
        "*::-webkit-scrollbar-thumb": {
          background: colors.black.lacquer["400"],
          borderRadius: "4px",
          "@media(max-width: 767.9px)": {
            background: colors.black.dark,
          },
        },
      },
    },
    // For datepicker input
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.black.lacquer[900],
          "& .MuiPickersDay-root": {
            backgroundColor: colors.black.lacquer[700],
          },
          "& .MuiSvgIcon-root": {
            fill: colors.black.lacquer[200],
          },
        },
      },
    },
    // For input field info
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...typography.descriptionPrio,
          fontWeight: 500,
          lineHeight: 1.2,
          backgroundColor: colors.black.lacquer[800],
          borderRadius: "16px",
          padding: "16px",
          maxWidth: "156px",
        },
        tooltipPlacementBottom: {
          marginTop: `8px !important`,
        },
        arrow: {
          color: colors.black.lacquer[800],
        },
      },
    },
    // For notification message
    MuiSnackbar: {
      styleOverrides: {
        root: {
          "& > .MuiPaper-root": {
            ...typography.mobileH3,
            lineHeight: "22px",
            borderRadius: "8px",
            width: "100%",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: colors.orange.hyperpop[50],
          color: colors.orange.hyperpop[700],

          "&>.MuiAlert-icon .MuiSvgIcon-root": {
            fill: colors.orange.hyperpop[700],
          },
        },
        standardSuccess: {
          backgroundColor: colors.green.hyperpop[200],
          color: colors.green.hyperpop[900],

          "&>.MuiAlert-icon .MuiSvgIcon-root": {
            fill: colors.green.hyperpop[900],
          },
        },
      },
    },
  },

  breakpoints: {
    values: breakpoints,
  },
}
export type CustomTheme = typeof theme
export type ThemeOverrides = typeof themeOverrides

export const theme = createTheme(themeOverrides, {})
export const customStyled = createStyled({ defaultTheme: theme })
