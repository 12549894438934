import { Box } from "@mui/material"
// styles
import { shouldForwardProp } from "styles/helpers"
import { customStyled } from "styles/theme"
// types
import { BackgroundProps, BackroundStyleProps } from "./types"

export const Background = customStyled(Box, {
  shouldForwardProp: (prop) =>
    shouldForwardProp<BackroundStyleProps>(
      ["withBackground", "fillColor", "backgroundSize", "backgroundVariant"],
      prop
    ),
})<BackgroundProps>(
  ({
    theme: {
      breakpoints,
      palette,
      transitions: {
        duration: { standard },
        easing: { easeInOut },
      },
      spacing,
    },
    withBackground,
    backgroundSize,
    backgroundVariant,
    fillColor,
    onClick,
    disabled,
  }) => ({
    display: "inline-block",
    transition: `all ${standard}ms ${easeInOut}`,
    "&  path, &  rect": {
      fill: fillColor ? fillColor : null,
      transition: `all ${standard}ms ${easeInOut}`,
    },
    ...(onClick && {
      cursor: "pointer",
      [breakpoints.up("sm")]: {
        "&:hover": {
          "&  path, &  rect": {
            fill: palette.secondary.main,
          },
        },
      },
    }),
    ...(disabled && {
      opacity: 0.6,
      cursor: "default",
    }),
    ...(withBackground && {
      borderRadius: "50%",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      ...(backgroundVariant === "primary" && {
        backgroundColor: palette.black.lacquer["800"],
        [breakpoints.up("sm")]: {
          "&:hover": {
            backgroundColor: palette.black.lacquer["700"],
          },
        },
      }),
      ...(backgroundVariant === "secondary" && {
        backgroundColor: palette.secondary.main,
        [breakpoints.up("sm")]: {
          "&:hover": {
            backgroundColor: palette.secondary.light,
          },
        },
      }),
      ...(backgroundVariant === "dark" && {
        backgroundColor: palette.black.lacquer["950"],
        [breakpoints.up("sm")]: {
          "&:hover": {
            backgroundColor: palette.black.lacquer["900"],
          },
        },
      }),
      ...(backgroundSize === "small" && {
        padding: spacing(2),
        width: 30,
        height: 30,
      }),
      ...(backgroundSize === "medium" && {
        padding: spacing(3),
        width: 40,
        height: 40,
      }),
      ...(backgroundSize === "large" && {
        padding: spacing(4),
        width: 55,
        height: 55,
      }),
      "& svg": {
        maxWidth: "90%",
      },
    }),
  })
)
