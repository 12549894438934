export const getUserInitials = (userName: string) => {
  return (
    (userName?.split(" ")?.[0]?.charAt(0) || "").toUpperCase() +
    (userName?.split(" ")?.[1]?.charAt(0) || "").toUpperCase()
  )
}

export const getCollapsedString = (string: string, maxCollapsedStringLength: number): string => {
  const isNeedToCollapse = string.length > maxCollapsedStringLength

  return isNeedToCollapse ? `${string.slice(0, maxCollapsedStringLength)}...` : string
}

export const normalizeCoursePrice = (coursePrice?: number): string => {
  if (!coursePrice) return ""

  const cents = String(coursePrice).slice(-2)
  const euros = String(coursePrice).slice(0, -2) || 0
  return euros + "," + cents
}
