import axios from "axios"
import qs from "qs"
// constants
import { languagesTransformer } from "i18n"
// types
import { Language } from "types/i18n"
import { TSpeaker } from "types/speaker"

type GetSpeakersRequest = {
  locale: Language
  filterValue?: string
}

export type GetSpeakersResponse = { data: TSpeaker[] }

export const SPEAKERS_KEYS = {
  FILTERED_SPEAKERS: "filtereSpeakers",
}

export const getSpeakers = async ({ locale = "en", filterValue }: GetSpeakersRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      ...(filterValue
        ? {
            filters: {
              $or: [
                {
                  speakerFirstName: {
                    $containsi: filterValue,
                  },
                },
                {
                  speakerLastName: {
                    $containsi: filterValue,
                  },
                },
                {
                  speakerTitle: {
                    $containsi: filterValue,
                  },
                },
                {
                  expertFor: {
                    $containsi: filterValue,
                  },
                },
                {
                  courses: {
                    courseName: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  courses: {
                    courseDescription: {
                      $containsi: filterValue,
                    },
                  },
                },
              ],
            },
          }
        : {}),
      locale: languagesTransformer[_locale],
      fields: ["speakerFirstName", "speakerLastName", "speakerTitle", "speakerPictureHash", "expertFor"],
      populate: {
        speakerPicture: {
          fields: ["ext", "url"],
        },
        publication: {
          populate: {
            publicationComponent: {
              fields: ["id", "publicationYear", "publicationType", "publicationMetainfo", "publicationName"],
            },
          },
        },
        curriculumVitae: {
          populate: {
            curriculumVitaeFields: {
              fields: ["id", "cvTimePeriod", "cvWorkStation", "cvLocation", "cvJobPosition", "cvDescription"],
            },
          },
        },
        courses: {
          fields: [
            "publishedAt",
            "courseType",
            "courseLength",
            "cmePoints",
            "cmePointsText",
            "courseName",
            "certified",
            "hashCourseCoverLandscape",
          ],
          populate: {
            episodes: {
              fields: ["episodeLength", "episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover"],
              populate: {
                episodeCover: {
                  fields: ["ext", "url"],
                },
              },
            },
            coverLandscape: {
              fields: ["ext", "url"],
            },
            speaker: {
              fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/speakers?${params}`

  const response = await axios.get<GetSpeakersResponse>(url)

  return response.data
}
