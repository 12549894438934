// styles
import { customStyled } from "styles/theme"

export const SNavigation = customStyled("div")(({ theme: { breakpoints } }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}))

export const SChannelInfo = customStyled("div")(
  ({
    theme: {
      palette: { green },
    },
  }) => ({
    fontSize: "18px",
    lineHeight: 1.2,
    "& a": {
      color: green.hyperpop[500],
    },
  })
)
