// styles
import { customStyled } from "styles/theme"

export const SDataProtection = customStyled("div")(({ theme: { palette, spacing, breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  margin: `${spacing(12)} auto`,
  width: "437px",
  fontSize: "14px",
  color: palette.black.lacquer[200],
  textAlign: "left",
  "& p > a": {
    color: palette.green.hyperpop[500],
  },

  [breakpoints.up("lg")]: {
    width: "620px",
  },
  [breakpoints.up("xl")]: {
    width: "820px",
  },
  [breakpoints.down("xs")]: {
    padding: spacing(5),
    margin: `${spacing(5)} auto`,
    width: "100%",
  },
}))
