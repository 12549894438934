/* eslint-disable max-classes-per-file */
class LocalStorage {
  static get(name: string) {
    return localStorage.getItem(name)
  }

  static set(name: string, value: any) {
    return localStorage.setItem(name, value)
  }

  static remove(name: string) {
    return localStorage.removeItem(name)
  }
}

export default LocalStorage
