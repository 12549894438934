//hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
//types
import { UseCardSizesParams } from "./types"

const useCardSizes = ({ cardSizes, variant, width, height }: UseCardSizesParams) => {
  const { isTablet } = useMUIBreakpoints()

  let cardWidth = width,
    cardHeight = height

  if (variant) {
    cardWidth = cardSizes[variant].width
    cardHeight = cardSizes[variant].height

    if (isTablet) {
      cardWidth = cardSizes[variant].mobileWidth
      cardHeight = cardSizes[variant].mobileHeight
    }
  }

  return {
    cardWidth,
    cardHeight,
  }
}

export default useCardSizes
