import { Tooltip } from "@mui/material"
// components
import Icon from "shared/components/Icon"
// styles
import { SIconWrapper } from "./styles"

type InfoIconProps = {
  title: string
}

const InfoIcon = ({ title }: InfoIconProps) => {
  return (
    <Tooltip title={title} placement="bottom-start" arrow>
      <SIconWrapper>
        <Icon name="infoBackground" />
      </SIconWrapper>
    </Tooltip>
  )
}

export default InfoIcon
