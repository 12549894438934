import { ReactElement, useEffect } from "react"
// history
import { history } from "../store"

const registerPageLoad = () => {
  // @ts-ignore
  const _paq = window._paq || []
  _paq.push(["setCustomUrl", history.location.pathname])
  _paq.push(["setDocumentTitle", history.location.pathname])
  _paq.push(["trackPageView"])
}

type Props = {
  children: ReactElement
}

const Matomo = ({ children }: Props) => {
  useEffect(() => {
    return history.listen(() => {
      registerPageLoad()
    })
  }, [history])

  return <>{children}</>
}

export default Matomo
