import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Alert, Snackbar, Typography } from "@mui/material"
import { TFunction } from "i18next"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
// hooks
import { useReportProblem } from "./hooks/useReportProblem"
import useToggleSnackbar from "hooks/useToggleSnackbar"
// components
import Button from "shared/components/Button"
import { TextField } from "shared/components/input"
import { yupResolver } from "@hookform/resolvers/yup"
// styles
import { SReport, SReportButtonWrapper } from "./styles"
export interface FormData {
  subject: string
  description: string
}

const validator = (t: TFunction) =>
  yup.object().shape({
    subject: yup.string().required(t("settings:report:subjectRequiredError")),
    description: yup.string().required(t("settings:report:descriptionRequiredError")),
  })

const Report = () => {
  const { t } = useTranslation()

  const { handleSubmit, reset, formState, control } = useForm<FormData>({
    resolver: yupResolver(validator(t)),
    defaultValues: {
      subject: "",
      description: "",
    },
    reValidateMode: "onChange",
  })

  const { submitReport, isLoading, isSuccess } = useReportProblem()
  const { open: openSnackbar, handleClose: handleCloseSnackbar } = useToggleSnackbar(isSuccess)

  const handleFormSubmit = useCallback(({ subject, description }: FormData) => {
    submitReport({ subject, description })
    reset()
  }, [])

  return (
    <SReport>
      <form onSubmit={handleSubmit(handleFormSubmit)} style={{ width: "100%" }}>
        <Controller
          name="subject"
          control={control}
          render={({
            field,
            formState: {
              errors: { subject },
            },
          }) => (
            <TextField
              inputProps={field}
              error={!!subject?.message}
              helperText={subject?.message}
              label={t("settings:report:subjectTitle")}
              sx={[
                (theme) => ({
                  mt: 13,
                  mb: 1,
                  [theme.breakpoints.down("xs")]: {
                    mt: 7,
                  },
                }),
              ]}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({
            field,
            formState: {
              errors: { description },
            },
          }) => (
            <TextField
              inputProps={field}
              error={!!description?.message}
              helperText={description?.message}
              multiline
              minRows={5}
              label={t("settings:report:descriptionTitle")}
              sx={{ mb: 4 }}
            />
          )}
        />

        <SReportButtonWrapper>
          <Button
            type="submit"
            disabled={!!Object.keys(formState.errors).length || isLoading}
            variant="contained"
            color="secondary"
            fullWidth
          >
            {t("settings:report:buttonTitle")}
          </Button>
        </SReportButtonWrapper>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          message={t("settings:report:submitSuccess")}
          onClose={handleCloseSnackbar}
          sx={{ position: "absolute", width: "80%" }}
        >
          <Alert severity="success" sx={{ width: "100%" }} onClose={handleCloseSnackbar} closeText="Close">
            {t("settings:report:submitSuccess")}
          </Alert>
        </Snackbar>
      </form>
      <Typography sx={{ width: "100%" }} mt={6} variant="description" color="black.lacquer.400">
        {t("settings:report:subtext")}
      </Typography>
    </SReport>
  )
}

export default Report
