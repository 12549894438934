import { Box } from "@mui/material"
// components
import Icon from "shared/components/Icon"
// styles
import { SLink, StyledNavLink } from "./styles"
// types
import { NavLinkProps } from "./types"

const NavLink = ({ to, children, icon, disableActive, sxLink }: NavLinkProps) => {
  return (
    <StyledNavLink to={to} data-testid={icon}>
      {({ isActive }: { isActive: boolean }) => (
        <SLink isActive={isActive && !disableActive} sx={sxLink}>
          {icon && (
            <Box sx={[(theme) => ({ mr: 6, [theme.breakpoints.down("sm")]: { mr: 0 } })]}>
              <Icon name={icon} />
            </Box>
          )}
          {children}
        </SLink>
      )}
    </StyledNavLink>
  )
}

export default NavLink
