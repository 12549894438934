import { Box } from "@mui/material"
// types
import { ContainerProps } from "./types"

const Container = ({ children, limited, sx }: ContainerProps) => {
  return (
    <Box
      sx={[
        (theme) => ({
          px: 11,
          ...(limited && {
            maxWidth: "1440px",
            width: "100%",
            margin: "0 auto",
          }),
          [theme.breakpoints.down("sm")]: {
            px: 5,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  )
}
export default Container
