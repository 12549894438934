import { NavLink } from "react-router-dom"
import { customStyled } from "styles/theme"

export const CardWrapper = customStyled(NavLink)(
  ({
    theme: {
      spacing,
      palette,
      shape: { borderRadius },
      transitions: {
        duration: { standard },
        easing: { easeInOut },
      },
      breakpoints,
    },
  }) => ({
    position: "relative",
    display: "block",
    textDecoration: "none",
    padding: `${spacing(6)} ${spacing(5)}`,
    borderRadius: borderRadius(6),
    backgroundColor: palette.black.lacquer["900"],
    color: palette.white.main,
    transition: `all ${standard}ms ${easeInOut}`,
    "&:hover": {
      backgroundColor: palette.black.lacquer["800"],
    },
    [breakpoints.down("sm")]: {
      padding: 0,
    },
  })
)
