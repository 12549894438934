// hooks
import { useCmeDashboard, DETAIL_SCREENS } from "./hooks/useCmeDashboard"
// screens
import {
  CmeDashboard as CmeDashboardScreen,
  WatchedCourses as WatchedCoursesScreen,
  PassedCmeTests as PassedCmeTestsScreen,
} from "./screens"

const CmeDashboard = () => {
  const {
    userCmePoints,
    watchedCoursesCount,
    passedCmeTestCoursesCount,
    watchedCoursesByYears,
    passedCmeTestCoursesByYears,
    handleOpenWatchedCourses,
    handleOpenPassedCmeTests,
    detailScreenOpen,
    handleCloseDetailScreen,
    isLoading,
  } = useCmeDashboard()

  return (
    <>
      {!detailScreenOpen && (
        <CmeDashboardScreen
          userCmePoints={userCmePoints}
          watchedCoursesCount={watchedCoursesCount}
          passedCmeTestCoursesCount={passedCmeTestCoursesCount}
          handleOpenWatchedCourses={handleOpenWatchedCourses}
          handleOpenPassedCmeTests={handleOpenPassedCmeTests}
          isLoading={isLoading}
        />
      )}
      {detailScreenOpen === DETAIL_SCREENS.WATCHED_COURSES && (
        <WatchedCoursesScreen
          watchedCoursesByYears={watchedCoursesByYears}
          onCloseDetailScreen={handleCloseDetailScreen}
        />
      )}
      {detailScreenOpen === DETAIL_SCREENS.PASSED_CME_TESTS && (
        <PassedCmeTestsScreen
          passedCmeTestCoursesByYears={passedCmeTestCoursesByYears}
          onCloseDetailScreen={handleCloseDetailScreen}
        />
      )}
    </>
  )
}

export default CmeDashboard
