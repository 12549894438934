import { useCallback } from "react"
import { useMutation } from "@tanstack/react-query"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// api
import { updateUserData, UpdateUserDataResponse, UpdateUserDataRequest } from "api"
// types
import { User } from "types/user"

export const useNotifications = () => {
  const {
    user: { userProfileId, subscribeNewCourses, subscribeYourQuestionAnswered },
    setUser,
  } = useAuthenticationContext()

  const { isLoading, mutate: updateUserDataMutate } = useMutation<
    UpdateUserDataResponse,
    unknown,
    UpdateUserDataRequest
  >({
    mutationFn: updateUserData,
    onSuccess: ({ data }: any) => {
      setUser((prev: User) => ({
        ...prev,
        subscribeNewCourses: !!data?.attributes?.subscribeNewCourses,
        subscribeYourQuestionAnswered: !!data?.attributes?.subscribeYourQuestionAnswered,
      }))
    },
  })

  const handleToggleNewCourseSubscribe = useCallback((e: React.SyntheticEvent, value: boolean) => {
    updateUserDataMutate({ userProfileId, subscribeNewCourses: value })
  }, [])

  const handleToggleQuestionAnsweredSubscribe = useCallback((e: React.SyntheticEvent, value: boolean) => {
    updateUserDataMutate({ userProfileId, subscribeYourQuestionAnswered: value })
  }, [])

  return {
    subscribeNewCourses,
    subscribeYourQuestionAnswered,
    handleToggleNewCourseSubscribe,
    handleToggleQuestionAnsweredSubscribe,
    isLoading,
  }
}
