//components
import Tag from "shared/components/Tag"
// types
import { TagsSetProps } from "./types"

const TagsSet = ({ tags }: TagsSetProps) => (
  <div style={{ lineHeight: 1 }}>
    {tags?.map(({ title, variant }) => (
      <Tag
        key={title}
        variant={variant}
        sx={[
          (theme) => ({
            mr: theme.spacing(3),
            mb: theme.spacing(2),
            "&:last-child": {
              mr: 0,
            },
            [theme.breakpoints.down("md")]: {
              mr: theme.spacing(2),
            },
          }),
        ]}
      >
        {title}
      </Tag>
    ))}
  </div>
)

export default TagsSet
