import { TFunction } from "i18next"
import * as yup from "yup"

export const passwordValidator = (t: TFunction) =>
  yup.object().shape({
    password: yup
      .string()
      .required(t("registration:inputFields:password:errors:required"))
      .min(8, t("registration:inputFields:password:errors:min")),
  })
