// styles
import { customStyled } from "styles/theme"

export const SMembership = customStyled("div")(({ theme: { spacing, breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: `${spacing(11)} ${spacing(13)}`,

  [breakpoints.down("xs")]: {
    padding: `${spacing(5)} ${spacing(7)}`,
  },
}))
