import { customStyled } from "styles/theme"
// types
import { SimpleLinkProps } from "./types"

const SimpleLink = customStyled("a")<SimpleLinkProps>(
  ({
    theme: {
      palette: { black },
      typography: { h5, mobileH4 },
      transitions: {
        duration: { standard },
        easing: { easeInOut },
      },
      breakpoints,
    },
  }) => ({
    ...h5,
    color: black.lacquer["400"],
    textDecoration: "none",
    transition: `all ${standard}ms ${easeInOut}`,
    "&:hover": {
      color: black.lacquer["300"],
    },
    [breakpoints.down("sm")]: {
      ...mobileH4,
    },
  })
)

export default SimpleLink
