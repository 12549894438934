import { TextField as MUITextField } from "@mui/material"
// types
import { CustomTextFieldProps } from "./types"
// styles
import { customStyled } from "styles/theme"
import { shouldForwardProp } from "styles/helpers"

export const STextField = customStyled(MUITextField, {
  shouldForwardProp: (prop) => shouldForwardProp(["isAutocomplete", "infoText"], prop),
})<CustomTextFieldProps>(
  ({
    theme: {
      palette,
      spacing,
      shape: { borderRadius },
      typography: { h5, h6, mobileH3, description, descriptionPrio },
      breakpoints,
    },
    label,
    multiline,
    isAutocomplete,
  }) => ({
    "&.MuiTextField-root": {
      width: "100%",
      position: "relative",
      paddingBottom: spacing(5),
      "& input:-webkit-autofill": {
        transition: "all 99999s ease-in-out",
      },

      ".MuiInputLabel-root": {
        ...h5,
        color: palette.black.lacquer["400"],
        transform: `translate(${spacing(5)}, ${spacing(6)})`,
      },

      ".MuiInputLabel-root.MuiInputLabel-shrink": {
        ...descriptionPrio,
        color: palette.black.lacquer["400"],
        fontWeight: 500,
        transform: `translate(${spacing(5)}, ${spacing(3)})`,

        [breakpoints.down("md")]: {
          fontWeight: 700,
        },
      },

      ".MuiInputLabel-root.MuiInputLabel-shrink + .MuiFilledInput-root .MuiInputBase-input": {
        ...(label &&
          !multiline &&
          !isAutocomplete && {
            padding: `${spacing(6)} ${spacing(5)} ${spacing(4)}`,
          }),
      },

      ".MuiFilledInput-root": {
        backgroundColor: palette.black.lacquer["950"],
        borderRadius: borderRadius(4),

        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 100px ${palette.black.lacquer["950"]} inset`,
            WebkitTextFillColor: "#fff",
            // borderRadius: borderRadius(4),
          },
        },

        "&:hover": {
          backgroundColor: palette.black.lacquer["950"],
        },

        ".MuiInputBase-input": {
          ...h6,
          lineHeight: "1",
          padding: `${spacing(5)} ${spacing(5)} ${spacing(5)}`,
          ...(!label && {
            padding: spacing(5),
          }),
          ...(multiline && {
            padding: `${spacing(2)} ${spacing(2)} ${spacing(5)}`,
          }),
          ...(isAutocomplete && {
            padding: `${spacing(2)} ${spacing(3)} ${spacing(4)}`,
          }),
          [breakpoints.down("md")]: {
            ...mobileH3,
            paddingLeft: spacing(5),
            ...(multiline && {
              paddingLeft: spacing(2),
            }),
            ...(isAutocomplete && {
              paddingLeft: spacing(3),
            }),
          },
        },
      },

      ".MuiFormHelperText-root": {
        ...description,
        position: "absolute",
        bottom: 0,
        left: 0,
      },
    },
  })
)
