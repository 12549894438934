// constants
import { AUTH_TOKEN, BEFORE_LOGIN_URL } from "constants/auth"
// utils
import LocalStorage from "utils/localStorage"

export const getToken = () => LocalStorage.get(AUTH_TOKEN)
export const setToken = (token: string) => LocalStorage.set(AUTH_TOKEN, token)
export const removeToken = () => LocalStorage.remove(AUTH_TOKEN)

export const getBeforeLoginUrl = () => LocalStorage.get(BEFORE_LOGIN_URL)
export const setBeforeLoginUrl = (beforeLoginUrl: string) => LocalStorage.set(BEFORE_LOGIN_URL, beforeLoginUrl)
export const removeBeforeLoginUrl = () => LocalStorage.remove(BEFORE_LOGIN_URL)
