import axios from "axios"
import qs from "qs"
// constants
import { languagesTransformer } from "i18n"
// types
import { Pagination } from "types/api"
import { Language } from "types/i18n"
import { Course, CourseAttributes, Episode, EpisodeAttributes, CourseTypes, Locales } from "types/courses"

export type GetCourseRequest = {
  id: string | number
  locale?: Language
}

export type GetCourseBySuperCourseTitleRequest = {
  superCourseTitle: string
  locale?: Language
}

export type GetCoursePublicRequest = {
  superCourseTitle: string
  locale?: Language
}

export type GetCourseResponse = {
  data: Course<
    Omit<
      CourseAttributes<Episode<Omit<EpisodeAttributes, "episodeCover" | "hashEpisodeCover">>[]>,
      "hashCourseCover" | "hashCourseCoverLandscape" | "cover" | "courseLength" | "cmePoints"
    >
  >
  meta: {
    pagination: Pagination
  }
}

export type GetCourseBySuperCourseTitleResponse = {
  data: { id: number }[]
  meta: {
    pagination: Pagination
  }
}

export type GetCoursePublicResponse = {
  id: number
  publishedAt: string
  courseType: CourseTypes
  courseLength: number
  courseLengthText: string
  cmePoints: number
  cmePointsText: string
  courseName: string
  certified: boolean
  courseDescription: string
  courseSources: string
  hashCourseCover: string
  hashCourseCoverLandscape: string
  episodes: {
    id: number
    episodeLength: number
    episodeLengthText: string
    episodeName: string
    publishedAt: string
    episodeNumber: number
    kalturaVideoId: string
    hashEpisodeCover: string
    hashEpisodeCoverLandscape: string
    episodeCover: {
      url: string
    }
    episodeCoverLandscape: {
      url: string
    }
    superEpisode: { id: number; title: string }
    localizations: { id: number; locale: Locales; kalturaVideoId: string }[]
    locale?: string
  }[]
  cover: {
    url: string
  }
  coverLandscape: {
    url: string
  }
  speaker: {
    id: number
    speakerTitle: string
    speakerFirstName: string
    speakerLastName: string
    speakerPicture: {
      url: string
    }
    speakerPictureHash?: string
  }
  videoLanguags: {
    videoLanguage: string
    localizationKey: string
  }[]
  intro: {
    id: number
    introName: string
    introKalturaVideoId: string
    introCoverLandscape: {
      url: string
    }
    localizations: { id: number; locale: Locales; introKalturaVideoId: string }[]
    introCoverHash?: string
    introCoverLandscapeHash?: string
  }
  coursePartner: {
    partnerName: string
    haveChannel: boolean
    partnerWebsite: string
    partnerContact: string
    partnerLogo: {
      url: string
    }
    partnerLogoHash?: string
  }
  superCourse: { title: string; id: number }
  locale?: string
  cmeTest: {
    id: number
  }
  specialties?: {
    name: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    locale: string
    specialtyImage?: {
      url: string
    }
    speacialtyImageHash?: string
  }[]
  coursePrice: number
}

export const getCourse = async ({ id, locale = "en" }: GetCourseRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      filters: {
        id: {
          $eq: id,
        },
      },
      fields: [
        "certified",
        "courseType",
        "courseName",
        "courseDescription",
        "courseSources",
        "cmePointsText",
        "courseLengthText",
        "publishedAt",
        "coursePrice",
      ],
      populate: {
        episodes: {
          filters: {
            publishedAt: {
              $notNull: true,
            },
          },
          fields: [
            "episodeLength",
            "episodeLengthText",
            "episodeName",
            "publishedAt",
            "episodeNumber",
            "kalturaVideoId",
            "hashEpisodeCoverLandscape",
          ],
          populate: {
            episodeCoverLandscape: {
              fields: ["ext", "url"],
            },
            localizations: {
              fields: ["locale", "kalturaVideoId", "id"],
            },
            superEpisode: {
              fields: ["id", "title"],
            },
          },
        },
        coverLandscape: {
          fields: ["ext", "url"],
        },
        coursePartner: {
          fields: ["partnerName", "partnerWebsite", "partnerContact", "partnerLogoHash", "haveChannel"],
          populate: {
            partnerLogo: {
              fields: ["ext", "url"],
            },
          },
        },
        speaker: {
          fields: ["speakerTitle", "speakerFirstName", "speakerLastName", "speakerPictureHash"],
          populate: {
            speakerPicture: {
              fields: ["ext", "url"],
            },
          },
        },
        intro: {
          fields: ["introName", "introKalturaVideoId", "introCoverLandscapeHash"],
          populate: {
            localizations: {
              fields: ["locale", "introKalturaVideoId"],
            },
            introCoverLandscape: {
              fields: ["ext", "url"],
            },
          },
        },
        videoLanguags: {
          fields: ["videoLanguage", "localizationKey"],
        },
        cmeTest: {
          fields: ["id"],
        },
        superCourse: {
          fields: ["id", "title"],
        },
        specialties: {
          fields: ["name"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/courses/${id}?${params}`

  const response = await axios.get<GetCourseResponse>(url)

  return response.data
}

export const getCourseBySuperCourseTitle = async ({
  superCourseTitle,
  locale = "en",
}: GetCourseBySuperCourseTitleRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      filters: {
        superCourse: {
          title: {
            $eq: superCourseTitle,
          },
        },
      },
      fields: ["id"],
    },
    { encodeValuesOnly: true }
  )

  const url = `/courses?${params}`

  const response = await axios.get<GetCourseBySuperCourseTitleResponse>(url)

  return response.data
}

export const getCoursePublic = async ({ superCourseTitle, locale = "en" }: GetCoursePublicRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      superCourseTitle,
    },
    { encodeValuesOnly: true }
  )

  const url = `/coursePublic?${params}`

  const response = await axios.get<GetCoursePublicResponse>(url)

  return response.data
}
