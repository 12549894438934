// styles
import { shouldForwardProp } from "styles/helpers"
import { customStyled } from "styles/theme"
// types
import { TagProps, TagStyleProps } from "./types"

const Tag = customStyled("div", {
  shouldForwardProp: (prop) => shouldForwardProp<TagStyleProps>(["variant"], prop),
})<TagProps>(
  ({
    theme: {
      palette,
      typography: { tag, mobileTag },
      spacing,
      shape: { borderRadius },
      transitions: {
        duration: { standard },
        easing: { easeInOut },
      },
      breakpoints,
    },
    onClick,
    variant,
  }) => ({
    ...tag,
    [breakpoints.down("sm")]: {
      ...mobileTag,
    },
    display: "inline-block",
    textTransform: "uppercase",
    borderRadius: borderRadius(2),
    padding: `${spacing(2)} ${spacing(3)}`,
    backgroundColor: "rgba(0,0,0,.32)",
    transition: `all ${standard}ms ${easeInOut}`,
    ...(onClick && {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.22)",
      },
    }),
    ...(variant === "secondary" && {
      backgroundColor: "rgba(0,0,0,.48)",
      color: palette.green.hyperpop["500"],
    }),
    ...(variant === "lightgreen" && {
      backgroundColor: "rgba(22, 243, 162, 0.16)",
      color: palette.green.hyperpop["500"],
    }),
    ...(variant === "filled" && {
      backgroundColor: palette.green.hyperpop["500"],
      color: palette.black.dark,
      ...(onClick && {
        "&:hover": {
          backgroundColor: palette.green.hyperpop["400"],
        },
      }),
    }),
    ...(variant === "gray" && {
      backgroundColor: "rgba(255, 255, 255, 0.16)",
      color: "inherit",
      ...(onClick && {
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.26)",
        },
      }),
    }),
  })
)

export default Tag
