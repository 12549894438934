import { AEONIK } from "./fonst"

export const typography = {
  h1: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontSize: "48px",
    lineHeight: "110%",
    fontWeight: 400,
  },
  h2: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "100%",
  },
  h3: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "110%",
  },
  h4: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "100%",
  },
  h5: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
  },
  h6: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "120%",
  },
  description: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
  },
  paragraph: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "140%",
  },
  tag: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "9px",
    lineHeight: "100%",
    letterSpacing: "5%",
  },
  descriptionPrio: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "100%",
  },
  mobileH1: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "120%",
  },
  mobileH2: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
  },
  mobileH3: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "100%",
    letterSpacing: "0.01em",
  },
  mobileH4: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14.4px",
  },
  mobileDescription: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "100%",
    letterSpacing: "2%",
  },
  mobileTag: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "8px",
    lineHeight: "100%",
    letterSpacing: "5%",
  },
  mobileParagraph: {
    fontFamily: AEONIK,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "150%",
    letterSpacing: "5%",
  },
}
