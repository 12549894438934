import axios from "axios"
import qs from "qs"
// constants
import { languagesTransformer } from "i18n"
import { PAGE_SIZE } from "constants/paginations"
// types
import { Language } from "types/i18n"
import { Pagination } from "types/api"
import { CourseTypes, Episode, EpisodeAttributes, IntroAttributes } from "types/courses"
import { Specialty, SpecialtyAttributes } from "types/specialties"

export type GetEpisodesRequest = {
  locale?: Language
  filterValue?: string
  page?: number
  pageSize?: number
}

export type GetIntrosRequest = {
  locale?: Language
  filterValue?: string
  page?: number
  pageSize?: number
}

export type TEpisode = Episode<
  Omit<
    EpisodeAttributes,
    | "episodeNumber"
    | "kalturaVideoId"
    | "episodeCoverLandscape"
    | "localizations"
    | "hashEpisodeCoverLandscape"
    | "episodeLength"
  > & {
    course: {
      data: {
        id: number
        attributes: {
          courseType: CourseTypes
          specialties: {
            data: Specialty<Pick<SpecialtyAttributes, "name">>[]
          }
        }
      }
    }
  }
>

export type TIntro = {
  id: number
  attributes: IntroAttributes & {
    publishedAt: string
    course: {
      data: {
        id: number
        attributes: {
          courseType: CourseTypes
          specialties: {
            data: Specialty<Pick<SpecialtyAttributes, "name">>[]
          }
        }
      }
    }
    introCover?: {
      data?: {
        id: number
        attributes: {
          ext: string
          url: string
        }
      }
    }
  }
}

export type GetEpisodesResponse = {
  data: TEpisode[]
  meta: { pagination: Pagination }
}

export const getEpisodes = async ({
  locale = "en",
  filterValue,
  page = 1,
  pageSize = PAGE_SIZE,
}: GetEpisodesRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      pagination: {
        page,
        pageSize,
      },
      sort: { id: "DESC" },
      filters: {
        course: {
          $and: [
            {
              courseType: {
                $ne: "shortUnit",
              },
            },
            {
              courseType: {
                $ne: "journal",
              },
            },
          ],
        },
        ...(filterValue
          ? {
              $or: [
                {
                  episodeName: {
                    $containsi: filterValue,
                  },
                },
                {
                  episodeKeywords: {
                    $containsi: filterValue,
                  },
                },
                {
                  course: {
                    courseName: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  course: {
                    courseDescription: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  course: {
                    specialties: {
                      name: {
                        $containsi: filterValue,
                      },
                    },
                  },
                },
              ],
            }
          : {}),
      },
      locale: languagesTransformer[_locale],
      populate: {
        fields: ["episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover"],
        episodeCover: {
          fields: ["ext", "url"],
        },
        course: {
          fields: ["id", "courseType"],
          populate: {
            specialties: {
              fields: ["name"],
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/episodes?${params}`

  const response = await axios.get<GetEpisodesResponse>(url)

  return response.data
}

export type GetIntrosResponse = {
  data: TIntro[]
  meta: { pagination: Pagination }
}

export const getIntros = async ({ locale = "en", filterValue, page = 1, pageSize = PAGE_SIZE }: GetIntrosRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      pagination: {
        page,
        pageSize,
      },
      sort: { id: "DESC" },
      filters: {
        ...(filterValue
          ? {
              $or: [
                {
                  course: {
                    courseName: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  course: {
                    courseDescription: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  introName: {
                    $containsi: filterValue,
                  },
                },
                {
                  course: {
                    specialties: {
                      name: {
                        $containsi: filterValue,
                      },
                    },
                  },
                },
                {
                  course: {
                    speaker: {
                      speakerFirstName: {
                        $containsi: filterValue,
                      },
                    },
                  },
                },
              ],
            }
          : {}),
      },
      locale: languagesTransformer[_locale],
      populate: {
        fields: ["introName", "introCoverHash", "publishedAt"],
        introCover: {
          fields: ["ext", "url"],
        },
        course: {
          fields: ["id", "courseType"],
          populate: {
            specialties: {
              fields: ["name"],
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/intros?${params}`

  const response = await axios.get<GetIntrosResponse>(url)

  return response.data
}

export type GetEpisodeBySuperEpisodeTitleRequest = {
  superEpisodeTitle: string
  locale?: Language
}

export type GetEpisodeBySuperEpisodeTitleResponse = {
  data: { id: number }[]
  meta: {
    pagination: Pagination
  }
}

export const getEpisodeBySuperEpisodeTitle = async ({
  superEpisodeTitle,
  locale = "en",
}: GetEpisodeBySuperEpisodeTitleRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      filters: {
        superEpisode: {
          title: {
            $eq: superEpisodeTitle,
          },
        },
      },
      fields: ["id"],
    },
    { encodeValuesOnly: true }
  )

  const url = `/episodes?${params}`

  const response = await axios.get<GetEpisodeBySuperEpisodeTitleResponse>(url)

  return response.data
}
