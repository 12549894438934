import { useToggleVisibility } from "hooks/useToggleVisibility"
// components
import Icon from "shared/components/Icon"
import InfoIcon from "./components/InfoIcon"
// styles
import { STextField } from "./styles"
// types
import { CustomTextFieldProps } from "./types"

const TextField = ({ infoText, InputProps, type, ...props }: CustomTextFieldProps) => {
  const { isVisible: showValue, handleToggle } = useToggleVisibility()

  return (
    <STextField
      {...props}
      variant="filled"
      type={showValue ? "text" : type}
      InputProps={{
        disableUnderline: true,
        ...(type === "password" && {
          endAdornment: <Icon onClick={handleToggle} title={infoText} name={showValue ? "eyeOpened" : "eyeClosed"} />,
        }),
        ...(infoText &&
          !props.inputProps?.value && {
            endAdornment: <InfoIcon title={infoText} />,
          }),
        ...InputProps,
      }}
    />
  )
}

export default TextField
