import { Box, useTheme } from "@mui/material"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
import { useWindowHeight } from "hooks/useWindowHeight"
import { useScrolldownToggle } from "./hooks/useScrolldownToggle"
// components
import Icon from "shared/components/Icon"
import NavLink from "shared/components/NavLink"
import NavigationMenu from "./NavigationMenu"
import UserCard from "./UserCard"
import Footer from "./Footer/Footer"
// constants
import { HOME } from "constants/routes"
// styles
import { CustomTheme } from "styles/theme"
// types
import { MainLayoutProps } from "./types"

const MainLayout = ({
  children,
  disableDesktopNavigation = false,
  disableMobileNavigation = false,
  limitedFooter = false,
}: MainLayoutProps) => {
  const theme = useTheme<CustomTheme>()
  const { isTablet } = useMUIBreakpoints()
  const { inner, outer, scrollRef } = useScrolldownToggle(!isTablet)

  const windowHeight = useWindowHeight()

  return (
    <Box
      sx={[
        (theme) => ({
          display: "flex",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
          },
        }),
      ]}
    >
      <Box
        sx={[
          {
            flexShrink: 0,
            backgroundColor: "black.main",
            width: 240,
            padding: "33px 0 26px",
            maxHeight: "100vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            transition: "transform .5s ease",
          },
          ({ breakpoints, spacing, zIndex: { menu } }) => ({
            [breakpoints.up("sm")]: {
              display: disableDesktopNavigation ? "none" : "flex",
            },
            [breakpoints.down("sm")]: {
              display: disableMobileNavigation ? "none" : "flex",
              position: "fixed",
              left: 0,
              top: 0,
              zIndex: menu,
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              padding: `${spacing(5)} ${spacing(6)}`,
              ...(inner && {
                transform: "translateY(0)",
                position: "fixed",
                minHeight: "auto",
              }),
              ...(outer && {
                transform: "translateY(-100%)",
                position: "fixed",
                minHeight: "auto",
              }),
            },
          }),
        ]}
      >
        <Box sx={({ breakpoints }) => ({ [breakpoints.up("sm")]: { mb: 13 } })}>
          <NavLink to={HOME} disableActive>
            <Box mr={4}>
              <Icon name="logo" fillColor="url(#paint0_linear_585_7114)" />
            </Box>
            <Icon name="logoName" fillColor={theme.palette.green.pastel} />
          </NavLink>
        </Box>
        {!isTablet && <NavigationMenu />}
        <Box sx={[({ breakpoints }) => ({ px: 7, [breakpoints.down("sm")]: { pr: 0 } })]}>
          <UserCard />
        </Box>
      </Box>
      <Box
        component="main"
        ref={scrollRef}
        sx={[
          ({ breakpoints }) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: "auto",
            height: "100vh",
            width: "100%",
            [breakpoints.down("sm")]: {
              paddingTop: disableMobileNavigation ? 0 : "72px", // height of fixed header
              height: disableMobileNavigation ? windowHeight : `${windowHeight - 76}px`, // - height of fixed navBar
            },
          }),
        ]}
      >
        {children}
        <Footer limited={limitedFooter} />
      </Box>
      {isTablet && !disableMobileNavigation && <NavigationMenu />}
    </Box>
  )
}

export default MainLayout
