// styles
import { customStyled } from "styles/theme"
import { shouldForwardProp } from "styles/helpers"

export const SWatchedCoursesWrapper = customStyled("div")(({ theme: { spacing, breakpoints } }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [breakpoints.down("sm")]: {
    padding: spacing(5),
  },
}))

export const SDescriptionWrapper = customStyled("div")(
  ({
    theme: {
      palette,
      shape: { borderRadius },
      spacing,
      breakpoints,
    },
  }) => ({
    width: "437px",
    backgroundColor: palette.green.mid,
    borderRadius: borderRadius(6),
    padding: spacing(9),

    [breakpoints.down("sm")]: {
      width: "100%",
    },
  })
)

export const SWatchedCourseWrapper = customStyled("div")(
  ({
    theme: {
      palette,
      shape: { borderRadius },
      spacing,
      breakpoints,
    },
  }) => ({
    width: "437px",
    backgroundColor: palette.black.lacquer[950],
    borderRadius: borderRadius(6),
    marginBottom: spacing(5),
    padding: spacing(5),
    display: "flex",
    alignItems: "center",

    [breakpoints.down("sm")]: {
      width: `calc(100vw - ${spacing(5)} - ${spacing(5)})`,
    },
  })
)

export const SWatchedCourseContentWrapper = customStyled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}))

export type CoursePreviewImageStyleProps = { previewImage: string }

export const CoursePreviewImage = customStyled("div", {
  shouldForwardProp: (prop) => shouldForwardProp<CoursePreviewImageStyleProps>(["previewImage"], prop),
})<CoursePreviewImageStyleProps>(({ theme: { shape, spacing }, previewImage }) => ({
  width: "65px",
  height: "65px",
  marginRight: spacing(5),
  borderRadius: shape.borderRadius(2),
  background: `url(${previewImage}) no-repeat center top`,
  backgroundSize: "cover",
  lineHeight: 1,
}))
