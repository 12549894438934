export const throttle = (callback: any, delay: number) => {
  let timeoutHandler: ReturnType<typeof setTimeout> | null = null

  return function () {
    if (timeoutHandler === null) {
      timeoutHandler = setTimeout(function () {
        callback()
        timeoutHandler = null
      }, delay)
    }
  }
}

export const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: ReturnType<typeof setTimeout> | undefined

  return function (...args: any[]) {
    const later = () => {
      timeout = undefined
      func(...args)
    }

    clearTimeout(timeout!)
    timeout = setTimeout(later, wait)
  }
}
