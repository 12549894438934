import axios from "axios"
import qs from "qs"
// constants
import { REACT_APP_STRAPI_API_URL } from "constants/endpoints"
import { languagesTransformer } from "i18n"
// types
import { Pagination } from "types/api"
import { Language } from "types/i18n"

export type GetCourseLikesRequest = {
  locale: Language
}

export type GetCourseLikesResponse = {
  data: {
    id: number
    attributes: {
      superCourse: {
        data: {
          id: number
        }
      }
    }
  }[]
  meta: Pagination
}

export type SetCourseLikeRequest = {
  courseId: number
}

export type RemoveCourseLikeRequest = {
  courseId: number
}

export type GetCourseDislikesRequest = {
  userId: number
  locale: Language
}

export type GetCourseDislikesResponse = {
  data: {
    id: number
    attributes: {
      superCourse: {
        data: {
          id: number
        }
      }
    }
  }[]
  meta: Pagination
}

export type SetCourseDislikeRequest = {
  courseId: number
}

export type RemoveCourseDislikeRequest = {
  courseId: number
}

export const LIKE_DISLIKE_KEYS = Object.freeze({
  GET_COURSE_LIKES: "getCourseLikes",
  SET_COURSE_LIKE: "setCourseLike",
  REMOVE_COURSE_LIKE: "removeCourseLike",
  GET_COURSE_DISLIKES: "getCourseDislikes",
  SET_COURSE_DISLIKE: "setCourseDislike",
  REMOVE_COURSE_DISLIKE: "removeCourseDislike",
})

export const getCourseLikes = async ({ locale = "en" }: GetCourseLikesRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      populate: {
        superCourse: {
          fields: ["id"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}super-course-likes?${params}`

  const response = await axios.get<GetCourseLikesResponse>(url)

  return response.data
}

export const setCourseLike = async ({ courseId }: SetCourseLikeRequest) => {
  const params = qs.stringify({}, { encodeValuesOnly: true })
  const url = `${REACT_APP_STRAPI_API_URL}super-course-likes?${params}`

  const response = await axios.post<any>(url, {
    data: {
      superCourse: courseId,
    },
  })

  return response.data
}

export const removeCourseLike = async ({ courseId }: RemoveCourseLikeRequest) => {
  const url = `${REACT_APP_STRAPI_API_URL}super-course-likes/${courseId}`

  // no need a type here cause we dont process the retrieved data
  const response = await axios.delete<any>(url, {
    data: {
      superCourse: courseId,
    },
  })

  return response.data
}

export const getCourseDislikes = async ({ locale = "en" }: GetCourseLikesRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      populate: {
        superCourse: {
          fields: ["id"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}super-course-dislikes?${params}`

  const response = await axios.get<GetCourseDislikesResponse>(url)

  return response.data
}

export const setCourseDislike = async ({ courseId }: SetCourseDislikeRequest) => {
  const params = qs.stringify({}, { encodeValuesOnly: true })
  const url = `${REACT_APP_STRAPI_API_URL}super-course-dislikes?${params}`

  const response = await axios.post<any>(url, {
    data: {
      superCourse: courseId,
    },
  })

  return response.data
}

export const removeCourseDislike = async ({ courseId }: RemoveCourseDislikeRequest) => {
  const url = `${REACT_APP_STRAPI_API_URL}super-course-dislikes/${courseId}`

  // no need a type here cause we dont process the retrieved data
  const response = await axios.delete<any>(url, {
    data: {
      superCourse: courseId,
    },
  })

  return response.data
}
