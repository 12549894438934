export const addMonths = (numOfMonths: number, date = new Date()) => {
  date.setMonth(date.getMonth() + numOfMonths)

  return date
}

export const isBefore = (date1: number, date2: number) => {
  return date1 < date2
}

export const isAfter = (date1: number, date2: number) => {
  return date1 > date2
}

export const getFormattedDate = (date: Date): string => {
  const yyyy = date.getFullYear()
  const mm = date.getMonth() + 1 // Months start at 0!
  const dd = date.getDate()

  return dd + "." + mm + "." + yyyy
}
