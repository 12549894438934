import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import remarkBreaks from "remark-breaks"
// hooks
import { useTerms } from "./hooks/useTerms"
// styles
import { STerms } from "./styles"

const Terms = () => {
  const { termsOfUse } = useTerms()

  return (
    <STerms>
      <ReactMarkdown remarkPlugins={[remarkGfm, remarkBreaks]}>{termsOfUse}</ReactMarkdown>
    </STerms>
  )
}

export default Terms
