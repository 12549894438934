import { useCallback, useEffect, useMemo, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import i18n, { languagesMap } from "i18n"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// api
import { updateUserData, UpdateUserDataRequest } from "api"

export const useToggleLanguage = () => {
  const {
    user: { userProfileId },
    refetchUser,
  } = useAuthenticationContext()

  const { mutate: updateUserLocaleMutate } = useMutation<unknown, unknown, UpdateUserDataRequest>({
    mutationFn: updateUserData,
    onSuccess: () => {
      i18n.changeLanguage(selectedLng.value)
      refetchUser()
    },
  })

  const languagesArray = useMemo(() => {
    const languagesArray = []

    for (const languageKey in languagesMap) {
      languagesArray.push({ label: languagesMap[languageKey], value: languageKey })
    }

    return languagesArray
  }, [languagesMap])

  const [selectedLng, setSelectedLang] = useState(languagesArray[0])

  useEffect(() => {
    setSelectedLang(languagesArray.find((l) => l.value === i18n.language) || languagesArray[0])
  }, [])

  const handleChangeSelectedLng = useCallback((e: React.SyntheticEvent, value: any) => {
    setSelectedLang(value)
  }, [])

  const handleChangeLng = useCallback(() => {
    updateUserLocaleMutate({ userLocale: selectedLng.value, userProfileId })
  }, [selectedLng])

  return { languagesArray, selectedLng, handleChangeSelectedLng, handleChangeLng }
}
