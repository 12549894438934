// styles
import { customStyled } from "styles/theme"

export const SCmeDashboardWrapper = customStyled("div")(({ theme: { spacing, breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [breakpoints.down("xs")]: {
    padding: spacing(5),
  },
}))

export const SCmeDashboardInner = customStyled("div")(
  ({
    theme: {
      palette,
      shape: { borderRadius },
      spacing,
      breakpoints,
    },
  }) => ({
    width: "437px",
    cursor: "pointer",
    backgroundColor: palette.black.lacquer[950],
    borderRadius: borderRadius(6),
    marginBottom: spacing(7),
    padding: spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    [breakpoints.down("xs")]: {
      width: "100%",
    },
  })
)
