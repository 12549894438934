import { useCallback } from "react"
import { useMutation } from "@tanstack/react-query"
// context
import useAuthenticationContext from "context/Authentication/useAuthenticationContext"
// api
import { submitReport } from "api"
// types
import { FormData } from "../Report"

export const useReportProblem = () => {
  const { user } = useAuthenticationContext()

  const {
    mutate: _submitReport,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: submitReport,
    onSuccess: () => {},
  })

  const onSubmitReport = useCallback(
    ({ subject, description }: FormData) => {
      _submitReport({ subject, description, userEmail: user.email, userFullName: user.name })
    },
    [user.email, user.name]
  )

  return { submitReport: onSubmitReport, isLoading, isSuccess }
}
