import { generatePath } from "react-router-dom"
import { history } from "../store"
import { buildQueryString } from "./buildQueryString"

export const getPath = (pattern: string, params = {}) => {
  let updatedRoute = pattern

  try {
    updatedRoute = generatePath(pattern, params)
  } catch {
    Object.keys(params).forEach((param) => {
      // @ts-ignore
      updatedRoute = updatedRoute.replace(`:${param}`, params[param])
    })
  }

  return updatedRoute
}

export default getPath

export const goTo = (pattern: string, params = {}) => {
  const path = getPath(pattern, params)

  history.push(path)
}

export const goToWithSearch = (pattern: string, search: any, params = {}) => {
  const searchParams = buildQueryString(search)

  const path = getPath(pattern, params)

  history.push({
    pathname: path,
    search: searchParams,
  })
}

export const goToWithState = (pattern: string, state: any, params = {}) => {
  const path = generatePath(pattern, params)

  history.push(
    {
      pathname: path,
    },
    state
  )
}
