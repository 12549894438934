import { takeLatest, all } from "redux-saga/effects"
// requests
import { createUserRequest } from "../requests"
// workers
import { createUser } from "./workers"
// utils
import { convertTypeFromAction } from "../../../utils/actions"

function* watchCreateUser() {
  yield takeLatest(convertTypeFromAction(createUserRequest), () => createUser())
}

export default function* UserSaga() {
  yield all([watchCreateUser()])
}
